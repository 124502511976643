import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddMaterial from './addMaterial';
import {  Delete_Material } from '../../../actions/curriculums/materials';
import { Table, Button, Modal } from 'react-bootstrap';
import AddLesson from '../lessons/addLesson';
import ViewLessons from '../lessons/viewLessons'
import { HomePage } from '../../../translations/trans';
import AddMock from './AddMock';
import ShowMocks from './ShowMocks';
function Materials() {
  const materials = useSelector(state => state.materials);
  const dispatch = useDispatch();
  const [deleteMaterialId, setDeleteMaterialId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirmation = (materialId) => {
    setDeleteMaterialId(materialId);
    setShowDeleteModal(true);
  };

  const handleDeleteMaterial = () => {
    // Dispatch the delete material action
    dispatch(Delete_Material(deleteMaterialId));
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };

  const handleCloseDeleteModal = () => {
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <div>
      <AddMaterial />

      <Table responsive>
        <thead>
          <tr>
            <th>{HomePage.material[lang]}</th>
            <th>{HomePage.materialCode[lang]}</th>
            <th>{HomePage.addLesson[lang]}</th>
            <th>{HomePage.lessons[lang]}</th>
            {/* <th>{HomePage.addTest[lang]}</th> */}
            <th>{HomePage.addMock[lang]}</th>
            <th>{HomePage.showMocks[lang]}</th>
            <th>{HomePage.delete[lang]}</th>
          </tr>
        </thead>
        <tbody>
          {materials.map(material => (
            <tr key={material._id}>
              <td>{material.name}</td>
              <td>{material.code}</td>
              <td>
                <AddLesson materialCode={material.code} />
              </td>
              <td><ViewLessons materialCode={material.code} /></td>
              <td>
                <AddMock materialId={material._id} />
              </td>
              <td>
                <ShowMocks materialId={material._id} mocks={material?.mocks} />
              </td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteConfirmation(material._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} dir={dir}>
        <Modal.Body>
          {HomePage.deleteMat[lang]}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            {HomePage.cancel[lang]}
          </Button>
          <Button variant="danger" onClick={handleDeleteMaterial}>
            {HomePage.delete[lang]}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Materials;
