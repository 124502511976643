import React, { useEffect,useState } from 'react'
import { useDispatch } from 'react-redux';
import { Get_Test } from '../../../actions/curriculums/test';
import { Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
function TestTaps({testIDS}) {
    const [message,setMessage] = useState(null);
   const dispatch = useDispatch();

   //get test
   const getTest = async(id)=>{
    try{
    
            const res = await dispatch(Get_Test([id]));
    }
    catch(err){
        console.log(err.message);

    }
   }
    // useEffect(()=>{

    // },[testIDS])
  return (
    <div>
        {testIDS.length?testIDS.map((id,i)=>
        <div key={i} className='test-buttons'>
          <NavLink to='/exam' onClick={()=>getTest(id)}>Exam {i+1}</NavLink>
        </div>):<Alert>لا توجد اختبارات لهذا الدرس</Alert>}
    </div>
  )
}

export default TestTaps