import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation } from 'react-router-dom';
import { urlBase } from '../../utils/API';
import ViewComment from './ViewComment';
import { changeMark, changeState } from '../../utils/mock';
import { useSelector } from 'react-redux';
import { ErrorMsg, success } from '../../utils/Toast';
import { HomePage } from '../../translations/trans';
import AddAnswerMock from './AddAnswerMock';

const MockDegree = () => {
    const location = useLocation();
    const { mock, answer } = location.state;

 

    const loggedUser = useSelector((state) => state.loggedUser)

    // approved   , cancelled

    const [degree, setDegree] = useState(answer?.marks === null ? 0 : answer?.marks);
    const [state, setState] = useState(answer?.state);

    const handleStateChange = async (e) => {
        const newState = e.target.value;  // Get the selected value
        try {
            const res = await changeState({ state: newState }, answer?._id, loggedUser?.user?._id);
            if (res?.data?.case) {
                success(res?.data?.message)
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data) {
                ErrorMsg(error?.response?.data?.message)
            }
        }
    };

    const handleDegree = async () => {
        try {
            const res = await changeMark({ marks: degree }, answer?._id, loggedUser?.user?._id)
            if (res?.data?.case) {
                success(res?.data?.message)
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    }


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='p-5' dir={dir}>
            <div className='d-flex justify-content-evenly align-items-center'>
                <h4 style={{ color: "#1F3662" }}>
                    {HomePage.mockName[lang]}:{mock?.mock?.name}
                </h4>
                <h4 style={{ color: "#1F3662" }}>
                    {HomePage.stdName[lang]}:{answer?.user?.name}
                </h4>


            </div>

            <div className='row justify-content-evenly align-items-center mt-5'>
                <div className='d-flex col-lg-6 col-sm-12 col-md-6'>
                    <label style={{ color: "#1F3662" }}> {HomePage.addDegree[lang]} : </label>
                    <input type="number" name="addDegree" id="addDegree" className='form-control w-50' value={degree} onChange={(e) => setDegree(e.target.value)} />
                    <button className="btn"
                        onClick={handleDegree}
                        style={{ background: "#6B8F04", color: "white" }} disabled={degree === 0 || degree < 0}>
                        {HomePage.addDegree[lang]}
                    </button>
                </div>
                <div className='col-lg-2 col-sm-6 col-md-3'>
                    {/* <Dropdown> */}
                    <select className="form-control" value={state} onChange={handleStateChange}>
                        <option hidden>{HomePage.changeState[lang]}</option>
                        <option style={{ background: "#1FAF38", color: "white" }} value={"approved"} className='btn'>{HomePage.submit[lang]}</option>
                        <option style={{ background: "#AF1F21", color: "white" }} value={"cancelled"} className='btn'>{HomePage.again[lang]}</option>
                    </select>

                </div>
                <div className='col-lg-2 col-sm-6 col-md-3'>
                    <ViewComment answer={answer} />
                </div>
                <div className='col-lg-2 col-sm-6 col-md-3'>
                    <AddAnswerMock answer={answer}/>
                </div>
            </div>
            <div className='mt-5'>
                <iframe src={`${urlBase}/${answer?.file}`} title="act" width="100%" height={"500px"}></iframe>
            </div>
        </div>
    )
}

export default MockDegree
