import { GET_BUY_REQUESTS,GET_NONE_ACTIVE_BUY_REQUESTS,GET_ACTIVE_BUY_REQUESTS, HANDEL_REQUEST} from "../actions/buyRequests";

export const buyRequests = (state=[],action)=>{
    switch (action.type) {
        case GET_BUY_REQUESTS:
            // console.log('reducer',action.data)
            return state=action.data;
        case HANDEL_REQUEST:
                     
            return state = state.map(s=>{
                const r =s;

                if(r._id ===action.req._id ){
                    return action.req
                }
                else{
                    return r
                }
            })
        default: return state;
    }
}