import React,{useState} from 'react'
// import FreeSession from './freeSession';
// import {onChooseItem} from '../globalFunc'
function ChooseStart(props) {
    const {hours,days,startDay,lang} = props;

    const sortedHours = hours.sort((a,b)=>{
      return new Date(`${a[a.index]}`) - new Date(`${b[b.index]}`)
    })
    const [activeButton, setActiveButton] = useState(null);

  const handleClick = (event) => {
    const button = event.target;
    setActiveButton(button.id);
  };
// console.log(sortedHours)
    return (
    <div className='choose-date-book' >
    
      {sortedHours.map((h, i) => {
        
        return (
          
            <>
                <span 
                key={i}
                id={'handelday'+i}
                className={activeButton == 'handelday'+i ? 'day-name-active day-name' : 'day-name'}
                onClick={(event)=>{
                  startDay(h);
                  handleClick(event)
}}>
                  {lang==='en'?days[h.index].name.split("").splice(0, 3).join(""):days[h.index].name}
                  <br />
                  {new Date(h[h .index]).getDate()} /{ new Date(h[h.index]).getMonth() + 1}
                </span>
             
        
            </>
        );
      })}
    
  </div>
  
    )
}

export default ChooseStart