import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Change_Class_Active_State, Update_Classes } from '../../../actions/class';
import { Modal, Button, Alert } from 'react-bootstrap';
import { HomePage } from '../../../translations/trans';
import { changePack } from '../../../utils/class';
import { ErrorMsg, success } from '../../../utils/Toast';

const EditClasspack = ({ classId }) => {
    const { packages } = useSelector(({ packages }) => ({ packages }));
    const [showModal, setShowModal] = useState(false);
    const [choosepack, setChoosePack] = useState(null);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();


    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = () => setShowModal(true);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const handleChangePackToClass = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Update_Classes({
                classId: classId,
                newPackId: choosepack,
                isUpdateUser: false
            }))
            // const res = await changePack({
            //     classId: classId,
            //     newPackId: choosepack,
            //     isUpdateUser: false
            // })
            if (res?.data?.case) {
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <button className='btn w-100' onClick={handleShow} style={{ backgroundColor: "#4169E2" }}>
                {HomePage.classSettings[lang]}
            </button>

            <Modal show={showModal} onHide={handleClose} dir={dir} centered>
                <Modal.Body>
                    <label>{HomePage.chooseProgram[lang]}</label>
                    <select className="form-control w-100 mt-4" onChange={(e) => setChoosePack(e.target.value)} value={choosepack}>
                        <option hidden>{HomePage.chooseProgram[lang]}</option>
                        {
                            packages.map((pack) => {
                                return (
                                    <option key={pack._id} value={pack._id}>{pack.name}</option>
                                )
                            })
                        }
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={handleChangePackToClass} disabled={loading}>
                        {!loading ? (HomePage.saveChanges[lang]) : ('loading.....')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditClasspack
