import { setSchedual, update_Teacher, delTeacher, getTeachersStd , getStudents } from "../utils/API"
import { authedUser } from './login&register';
export const INETIALIZE_TEACHERS = "INETIALIZE_TEACHERS"
export const SET_SCHEDUAL = "SET_SCHEDUAL"

const inetialize = (teachers, totalDocs) => {
    return {
        type: INETIALIZE_TEACHERS,
        teachers,
        totalDocs
    }
}
const Schedual = (schedual, id) => {
    return {
        type: SET_SCHEDUAL,
        schedual,
        id
    }
}
// export const Inetialize_Teachers = ()=>{//teachers is an array
//     return (dispatch)=>{
//         return getTeachers().then(res=>{
//             console.log(res)
//             dispatch(inetialize(res.data, res?.data.totalDocs))
//         })
//     }
// }
// export const Inetialize_TeachersStd = (page = 1, limit = 4) => {
//     return (dispatch) => {
//         return getStudents(page, limit).then(res => {
//             dispatch(inetialize(res.data, res.data.totalDocs));
//         });
//     };
// };
export const Set_Schedual = (schedual, id) => {
    return (dispatch) => {
        return setSchedual({ schedual: schedual, email: id }).then((res) => {
            if (res.status === 200) {
                dispatch(Schedual(schedual, id))
                dispatch(authedUser({ user: res.data, auth: true }))
                alert('Saved')
            }
            else { alert('error') }
        })
    }
}
export const UPDATE_TEACHER = "UPDATE_TEACHER";

const updateTeacher = (id, teacher) => {
    return {
        type: UPDATE_TEACHER,
        id,
        teacher
    }
}
export const Update_Teacher = (teacher) => {
    return (dispatch) => {
        return update_Teacher(teacher).then((res) => {
            if (res.status === 201) {
                dispatch(updateTeacher(teacher._id, teacher))
                alert('Saved')
            }
            else { alert('error') }
        })
    }

}
export const DELETE_TEACHER = "DELETE_TEACHER";
const deleteTeacher = (id) => {
    return {
        type: DELETE_TEACHER,
        id
    }
}
export const Delete_Teacher = (id) => {
    return (dispatch) => {
        return delTeacher(id).then(res => {
            dispatch(deleteTeacher(id))
            alert(res.data)
        })
    }

}