import React,{useEffect} from 'react'
import thanksIMG from '../../assets/Thankyou.png'
import { BsInstagram } from "react-icons/bs";
import { AiFillTwitterSquare,AiFillFacebook,AiFillLinkedin, AiOutlineTwitter, } from "react-icons/ai";
import{ImLinkedin2} from 'react-icons/im'
import {FaInstagramSquare,FaWhatsappSquare} from 'react-icons/fa'
// import {useDispatch} from 'react-redux'
import { intializeLogIn } from "../../actions/login&register";
import { useDispatch, useSelector } from "react-redux";
import GetText from '../translation/getText'
function Thanks() {
  const dispatch = useDispatch();

useEffect(()=>{
  dispatch(intializeLogIn());

},[])
  return (
    <GetText>
   <div className='thanks-page'>
    <div className='thanks-img'>
      <img src={thanksIMG}/>
    </div>
    <div id='thanks1'>
      <p id='th11'>
      Thank you for booking through our website. We appreciate your trust in us and we look forward to meeting your expectations during your stay. You will be contacted to confirm booking details and payment procedures. Thanks again and we wish you a pleasant stay.”
      </p>
      </div> 
      <div className='thanks-btns'>
        <a href='/' id='th345'>Back To Home Page</a>
        <span id='th22'> folow us </span>
      </div>
      <div className='thanks-social'>
      <div className="social-cont">
      <a className={"sociall "} href={"https://facebook.com"}>
              <AiFillTwitterSquare
              color='#00b7ff'
                size="28px"
              />
            </a>
            {/* <a className={"sociall "} href={"https://facebook.com"}>
              <AiFillLinkedin size="28px" 
              color='#0077b5' />
            </a> */}
            <a className="sociall" href={"https://facebook.com"}>
              <FaWhatsappSquare
              color='#32d121'
                size="28px"   
              />
            </a>
           
           
            {/* <a className="sociall" href={"https://facebook.com"}>
              <AiFillFacebook
              color='#1976d2'
                size="28px"   
              
              />
            </a> */}
            <a className={"sociall "} href={"https://facebook.com"}>
              <FaInstagramSquare 
              color='#ec4a64'
              size="28px"  />
            </a>
           
         
          
      </div>
    </div>
   </div>
   </GetText>
  )
}

export default Thanks