import axios from "axios"
import { urlBase } from "./API"

// Api For Check Out (supscripe to package)
export const subscripeToPackage = async (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/checkout`,
        data: data,
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Cookie': 'connect.sid=s%3A6EWgqRcRD8WgoIAE3SWeDGd88gfGH1qK.qCX7TTKFDmwN%2FqeDdAfjFkoyYp7FxfHSUuc9iRi4bHM'
        // },
        withCredentials: true,
    })
    // const response = await fetch(`${urlBase}/api/checkout`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Cookie': 'connect.sid=s%3Av3GDhCoutC1orCGV7oK2qoxuQ7NURCYI.jE2oQopT5kmqZnarLUnlp7b8Wqmg2OK%2BKGGiY13AtkM'
    //     },
    //     body: JSON.stringify({
    //         name: "Medical2 Engineer2 wanted",
    //         branchId: "66dfafd6741b89331b402190"
    //     })
    // });
    // return response;
}


// const response = await axios.post('http://127.0.0.1:8080/api/checkout',
//     {
//       name: "Medical2 Engineer2 wanted",
//       branchId: "66dfafd6741b89331b402190"
//     },
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         'Cookie': 'connect.sid=s%3AU6E014sO0mBdYC5BBjjzTaf2nKFynGyf.qWUeYwEIJDnNaf8zCXlzFkoNGZIL9g5whT98kZzQtJE'
//       }
//     }
//   );



export const checkoutPayPal = (data, packId, userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/packages/${packId}/paypal/checkout?userId=${userId}`,
        data: data,
        withCredentials: true,
    })
}



// Pay ment Excuese
export const paymentExecuse = (data,userId)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/paypal/execute-payment?userId=${userId}`,
        data:data
    })
}