import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getRequestsForStudent, urlBase } from '../../utils/API'
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
const ModalGetReq = ({ show, handleClose, std }) => {
    const [requests, setRequests] = useState([]);
    useEffect(() => {
        const handleGetReq = async () => {
            try {
                const res = await getRequestsForStudent(std?._id);
                console.log("rerererererere", res)
                if (res?.data?.case) {
                    setRequests(res?.data?.requests)
                } else {
                }
            } catch (e) {
                console.log(e)
            }
        }


        handleGetReq();
    }, [std])
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Requests For {std?.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>name</th>
                                <th>status</th>
                                <th>note</th>
                                <th>price</th>
                                <th>Discount Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requests.map((req) => (
                                <tr key={req._id}>
                                    <td>
                                        <img
                                            src={`${urlBase}/${req.coverImage}`}
                                            alt=""
                                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                        />
                                    </td>
                                    <td>{req?.packData?.name}</td>
                                    <td>{req?.response}</td>
                                    <td>{req?.note}</td>
                                    <td>{req?.packData?.price}</td>
                                    <td>{req?.packData?.discountPrice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalGetReq
