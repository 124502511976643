import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Buy_Requests, Handel_Request } from '../../../actions/buyRequests';
import MenuSelection from './menuSelection';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { getStudents, urlBase } from '../../../utils/API';
import { getValidRequests, getNotValidRequests, changeRequestState } from '../../../utils/packagesRequests';
import { HomePage } from '../../../translations/trans';
const origin = window.origin;

function formatDateString(dateString) {
  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}

function BuyRequests() {
  const [status, setStatus] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectedRequestId, setSelectedRequestId] = useState('');
  const [Response, setResponse] = useState('');

  const dispatch = useDispatch();

  const allUsers = useSelector(state => state.users.teachers)
  // console.log(allUsers);
  // const [allUsers,setAllUsers] = useState([])

  useEffect(() => {
    dispatch(Get_Buy_Requests())
    // dispatch(Get_)

  }, [])
  const { active_Requests, un_Active_Requests } = useSelector(mapStateToProps);


  // console.log(allUsers);
  // const getUserName = (request) => {
  //   try {
  //     // console.log(request.userID,allUsers)
  //     // allUsers.map(u => console.log(u._id))
  //     console.log(allUsers?.results.find(u=>u._id===request.userID))
  //     return allUsers?.results.filter(u => u._id === request?.userID)[0]?.name
  //   } catch (e) {
  //     console.log(e.message)

  //   }
  // }

  const handleViewImage = (imageLink) => {
    setSelectedImage(imageLink);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenConfirmationModal = (actionType, requestId) => {
    setActionType(actionType);
    setSelectedRequestId(requestId);
    setConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setActionType('');
    setSelectedRequestId('');
    setResponse('');
    setConfirmationModal(false);
  };

  const handleConfirmAction = async () => {
    try {
      await dispatch(Handel_Request(
        { id: selectedRequestId, valid: actionType === 'Accept' ? true : false, note: Response }
      ))

      handleCloseConfirmationModal();
    } catch (err) {
      console.warn(err);
      setResponse(err.message)
    }

  };


  const requests = status ? active_Requests : un_Active_Requests;
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  return (
    <div>
      <br />


      <MenuSelection
        setStatus={setStatus}
        status={status}
        name2={`${HomePage.newSubscriptions[lang]}`}
        name1={`${HomePage.oldSubscriptions[lang]}`}
      />
      <br />
      <br />
      <div>
        <h1>{!status ? HomePage.newSubscriptions[lang]:HomePage.oldSubscriptions[lang]}</h1>
        <Table responsive>
          <thead>
            <tr>
              <th>package</th>
              <th>{HomePage.nameForm[lang]}</th>
              <th>Email</th>
              <th>Phone</th>
              <th> price</th>
              <th>date</th>
              {status && <th>الحالة</th>}
              {!status && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {requests?.sort((a,b)=>new Date(b.date)-new Date(a.date))?.map((request) => (
              <tr key={request._id}>
                <td>{request?.packData?.name}</td>
                <td>{request?.userID?.name}</td>
                <td>{request?.userID?.email}</td>
                <td>{request?.userID?.phone}</td>


                <td>{request?.packData?.price}</td>
                <td>{formatDateString(request.date)}</td>
                {status && <td>{request?.response}</td>}
                <td>
                  <Button variant="primary" onClick={() => handleViewImage(`${urlBase}/${request?.receipt?.name}.${request?.receipt?.type}`)}>
                    View
                  </Button>
                  {' '}
                  {!status && (
                    <>
                      <Button variant="success" onClick={() => handleOpenConfirmationModal('Accept', request._id)}>
                        Accept
                      </Button>
                      {' '}
                      <Button variant="danger" onClick={() => handleOpenConfirmationModal('Reject', request._id)}>
                        Reject
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>View Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={selectedImage} alt="Receipt" style={{ width: '100%' }} />
          </Modal.Body>
        </Modal>
        <Modal show={confirmationModal} onHide={handleCloseConfirmationModal}>
          <Modal.Header closeButton>
            <Modal.Title>تاكيد طلب الاشتراك</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formResponse">
              <Form.Label>اضف الرد على الطلب:</Form.Label>
              <Form.Control
                type="text"
                placeholder="ردك على الطلب"
                value={Response}
                onChange={(e) => setResponse(e.target.value)}
              />
            </Form.Group>
            <p>Are you sure you want to {actionType.toLowerCase()} this request?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmationModal}>
              الغاء
            </Button>
            <Button variant={actionType === 'Accept' ? 'success' : 'danger'} onClick={handleConfirmAction}>
              تاكيد
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

function mapStateToProps({ buyRequests }) {
  // console.log("buyRequests", buyRequests)
  const active_Requests = buyRequests ? buyRequests.filter((b) => b.response !== 'PROCESSING') : [];
  const un_Active_Requests = buyRequests ? buyRequests?.filter((b) => b.response === 'PROCESSING') : [];
  return { active_Requests, un_Active_Requests };
}

export default BuyRequests;
