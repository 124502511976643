import { CREATE_MATERIAL, DELETE_MATERIAL, GET_MATERIALS, UPDATE_MATERIAL } from "../actions/curriculums/materials"
import { ADD_NEW_MOCK, DELETE_MOCK, UPDATE_MOCK } from "../actions/mockAction"

export const materials = (state = [], action) => {
    switch (action.type) {
        case CREATE_MATERIAL:
            return state = [...state, action.material]
        case UPDATE_MATERIAL:
            return state = state.map(s => {
                if (s._id === action.material._id) {
                    return action.material
                }
                return s
            })
        case ADD_NEW_MOCK:
         
            return state = state.map(s => {
                if (s._id === action.materialId) {
                    return { ...s, mocks: [...s.mocks, action.mock] }
                }
                return s
            })
        case DELETE_MOCK:

            return state.map(material => {

                if (material._id === action.materialId) {

                    return {
                        ...material,
                        mocks: material.mocks.filter(mock => mock._id !== action.id)
                    };
                }
                return material; // Return unchanged material if it's not the one being updated
            });
        case UPDATE_MOCK:
   
            return state.map(material => {
                // Find the material by materialId
                if (material._id === action.materialId) {
                    return {
                        ...material,
                        // Update the specific mock by matching mock._id
                        mocks: material.mocks.map(mock =>
                            mock._id === action.mock._id ? action.mock : mock
                        )
                    };
                }
                return material; // Return unchanged material if it's not the one being updated
            });
        case DELETE_MATERIAL:
            return state = state.filter(s => s._id !== action.id)
        case GET_MATERIALS:
            return state = action.materials
        default: return state
    }
}
