import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Form, Modal, Table } from "react-bootstrap";

import { FcFullTrash } from "react-icons/fc";
import { BiMessageDetail } from "react-icons/bi";
import { Delete_Student, Inetialize_Students } from "../../actions/students.js";
import { Current_Product } from "../../actions/product.js";
import NewAccount from "./newAccount/newAccount.jsx";
import { urlBase } from "../../utils/API.js";
import NewRequest from "../curriculums/requests/newRequest.js";
import { HomePage } from "../../translations/trans.js";
import { useNavigate } from "react-router-dom";
import ModalUpdateUser from "./requests/ModalUpdateUser.js";
import Pagination from "../../utils/Pagination.js";
// import { Inetialize_TeachersStd } from "../../actions/teachers.js";
import ModalGetReq from "./ModalGetReq.js";

function Students() {
  const [render, setRender] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const dispatch = useDispatch();
  // const { students, totalDocs } = useSelector(mapStateToProps);
  const students = useSelector((state) => state.users.teachers);
  const totalDocs = useSelector((state) => state.users.totalDocs);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  // const limit = 4;

  const [limit, setLimit] = useState(4);

  const [searchField, setSearchField] = useState('');




  useEffect(() => {
    dispatch(Inetialize_Students(currentPage, limit))
  }, [])
  useEffect(() => {
    dispatch(Inetialize_Students(currentPage, limit, searchField, searchValue));
  }, [currentPage, limit]);



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Do something on componenmount or when render state changes
  }, [render]);

  const handleDeleteStudent = (studentId) => {
    setStudentToDelete(studentId);
    setShowModal(true);  // Show confirmation modal
  };

  const confirmDelete = () => {
    if (studentToDelete) {
      dispatch(Delete_Student(studentToDelete));
      setRender(!render);
      setShowModal(false);  // Close modal after deletion
    }
  };

  const navigate = useNavigate();

  const loggedUser = useSelector((state) => state.loggedUser)

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };





  const filteredStudents = students?.results;


  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  const handleViewMessages = (student) => {
    dispatch(Current_Product(student));
    navigate(`/user/${loggedUser.user._id}/messages`);
  };


  const totalPages = totalDocs;




  const [showModalReq, setShowModalReq] = useState(false);
  const [std, setStd] = useState(null)
  const getRequestData = (student) => {
    setShowModalReq(true);
    setStd(student)
  }

  const handleSearchSubmit = async(e) => {
    try{
    e.preventDefault();
    await dispatch(Inetialize_Students(currentPage, limit, searchField, searchValue.trim()));
    }catch(e){
      console.warn(e)
    }
  };


  return (
    <div >
      <Row className="mb-4">
        <Col xs={12} md={8}>
          <NewAccount type="student" />
        </Col>
        <Col xs={12} md={4}>
          <Form.Control
            type="text"
            placeholder="Search by name, email, or phone"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Col>
        <Col xs={12} md={2}>
          <Form.Select
            value={limit}
            onChange={(e) => {
              setLimit(Number(e.target.value));
              setCurrentPage(1); // Reset to first page when limit changes
            }}
          >
            <option hidden>Choose Limit</option>
            <option value={5}>5</option>
            <option value={8}>8</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </Form.Select>
        </Col>
        <Col xs={12} md={2}>
          <Form.Select
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option hidden>Select Type Search</option>
            <option value="username">Name</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
            <option value="code">Code</option>
            <option value="parentPhone">parentPhone</option>
          </Form.Select>
        </Col>
        {searchField && (
          <Col xs={12} md={4}>
            <Form onSubmit={handleSearchSubmit}>
              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  placeholder={`Search by ${searchField}`}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value.trim())}
                />
                <Button type="submit" variant="primary">
                  Search
                </Button>
              </div>
            </Form>
          </Col>
        )}
      </Row>

      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Image</th>
            <th>Username</th>
            <th>Email</th>
            <th>Code</th>
            <th>Phone</th>
            <th>Registration Date</th>
            <th>Parent Name</th>
            <th>Parent Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents?.map((student) => (
            <tr key={student._id}>
              <td>
                <img
                  src={student?.personalImage ? `${urlBase}/uploads/${student.personalImage}` : ''}
                  alt=""
                  style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
              </td>
              <td>{student?.username}</td>
              <td>{student?.email}</td>
              <td>{student?.code}</td>
              <td>{student?.phone}</td>
              <td>{student?.date?.split("T")[0]}</td>
              <td>{student?.parentName}</td>
              <td>{student?.parentPhone}</td>
              <td>
                <button className="btn btn-primary" onClick={() => getRequestData(student)}>Requests</button>
              </td>
              <td>
                <div className="d-flex gap-2">
                  <Button variant="danger" size="sm" onClick={() => handleDeleteStudent(student._id)}>
                    <FcFullTrash size="20px" />
                  </Button>
                  <Button variant="info" size="sm" onClick={() => handleViewMessages(student)}>
                    <BiMessageDetail size="20px" />
                  </Button>
                  <NewRequest userID={student?._id} valid={true} />
                  <ModalUpdateUser student={student} type="admin" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination onPress={(pageNumber) => setCurrentPage(pageNumber)} countsPage={totalPages} />

      {/* Keep the existing delete confirmation modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} dir={dir}>
        <Modal.Body>{HomePage.deleteStudent[lang]}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {HomePage.cancel[lang]}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {HomePage.yes[lang]}
          </Button>
        </Modal.Footer>
      </Modal>


      <ModalGetReq show={showModalReq} handleClose={() => setShowModalReq(false)} std={std} />
    </div>
  );
}


function mapStateToProps({ users, loggedUser }) {

}

export default Students;



