import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setResult } from '../../../utils/results';
import { Card, ListGroup, ListGroupItem, Form, Modal, Button } from 'react-bootstrap';
import { CheckParticipate, Set_Result } from '../../../actions/curriculums/test';
import TestResults from './testResults';
import { urlBase } from '../../../utils/API';
import ShowTestResult from './ShowTestResult';
import { ErrorMsg } from '../../../utils/Toast';

function ViewTest({ userID }) {
    const [answers, setAnswers] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [testResult, setTestResult] = useState(null);
    const [checkTest, setCheckTest] = useState(false);
    const [tries, setTries] = useState(null)
    const [forceUpdate, setForceUpdate] = useState(false); // Add state for forcing re-render
    const dispatch = useDispatch();
    const { test } = useSelector(state => ({
        test: state.test
    }));

    const checkStudentCanExam = async () => {
        try {
            const res = await dispatch(CheckParticipate({
                testID: test?._id,
                studentID: userID
            }));
            if (res?.data?.case) {
                setCheckTest(res?.data?.case);
                setTries(res?.data?.tries)
            }
        } catch (e) {
            console.log(e);
            if (e?.response?.data?.case === false) {
                setCheckTest(e?.response?.data?.case);
            }
        }
    };

    useEffect(() => {
        checkStudentCanExam();
    }, [test?._id]);

    const handleSetResult = async () => {
        try {
            if (!test) {
                console.error('No test available to set result.');
                return;
            }

            let total = 0;
            let result = 0;

            const userAnswers = test.questions.map((question, index) => {
                const correctAnswer = question.choices.find(choice => choice.correct);
                const chosenAnswer = answers[index];
                const isCorrect = correctAnswer && correctAnswer.value === chosenAnswer;

                if (isCorrect) {
                    result += Math.abs(question.degree);
                }
                total += Math.abs(question.degree);

                return {
                    question: question.question,
                    choices: question.choices.map(choice => ({
                        value: choice.value,
                        correct: choice.correct
                    })),
                    degree: parseFloat(question.degree),
                    chosenAnswer: chosenAnswer
                };
            });

            const resultData = {
                userID: userID,
                testID: test._id,
                result: result,
                total: total,
                questions: userAnswers
            };

            // Dispatch action to set result
            const res = await setResult(resultData);


            if (res?.data?.case === false) {
                ErrorMsg(res?.data?.message);
            }

            // Update test result
            setTestResult(res.data.result);
            dispatch(Set_Result(res.data.result));

            // Show modal
            if (res.data.case) {
                setShowModal(true);
                checkStudentCanExam();
                setForceUpdate(!forceUpdate); // Toggle force update state
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleSelectAnswer = (questionIndex, choiceValue) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionIndex]: choiceValue
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTestResult(null);
    };


    return (
        <div style={{ direction: 'ltr' }}>
            <div>
                {
                    checkTest ? (
                        <div>
                            {/* Always Rendered ShowTestResult */}
                            {
                                tries === 0 ? (
                                    null
                                ) : (
                                    tries >= 1 && <ShowTestResult userId={userID} test={test} />
                                )
                            }


                            {test && test.questions ? (
                                <div className='mt-5'>
                                    <h2>{test.name}</h2>
                                    {test.questions.map((question, index) => (
                                        <Card key={index} className="mb-3">
                                            <Card.Body >
                                                <Card.Title>{`Question ${index + 1}: ${question.question}`}</Card.Title>
                                                {
                                                    question.file !== "" ? (
                                                        <div className='text-center  mb-5' style={{ width: "60%", margin: "auto", height: "50vh", backgroundSize: "cover" }}>
                                                            {
                                                                question.file !== "" ? (
                                                                    <img src={`${urlBase}/${question.file}`} alt='ImageData' style={{ width: "100%", height: "100%" }} />
                                                                ) : null
                                                            }

                                                        </div>
                                                    ) : null
                                                }

                                                <ListGroup>
                                                    {question.choices.map((choice, choiceIndex) => (
                                                        <ListGroupItem key={choiceIndex}>
                                                            <Form.Check
                                                                type="radio"
                                                                name={`question-${index}`}
                                                                id={`question-${index}-choice-${choiceIndex}`}
                                                                label={choice.value}
                                                                checked={answers[index] === choice.value}
                                                                onChange={() => handleSelectAnswer(index, choice.value)}
                                                            />
                                                        </ListGroupItem>
                                                    ))}
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                    <button onClick={handleSetResult} className="btn btn-primary">Submit Test</button>

                                    {/* Modal for displaying test result */}
                                    <Modal show={showModal} onHide={handleCloseModal} dir={'rtl'}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>نتيجة الاختبار</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {testResult && (
                                                <div>
                                                    <TestResults />
                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                اغلاق
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            ) : (
                                <h2>جارى التحميل...</h2>
                            )}
                        </div>
                    ) : (
                        <div>
                            {/* Always Rendered ShowTestResult */}
                            <ShowTestResult userId={userID} test={test} />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default ViewTest;
