import React, { useEffect, useState } from 'react';
import AddClass from './addClass';
import { Get_Classes } from '../../../actions/class';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import MenuSelection from '../requests/menuSelection';
import DeactivateClass from './deactivateClass';
import AddStudents from './addStudents';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoSettings } from "react-icons/io5";
import SendMessage from '../../message/sendMessage';
import { IoIosSend } from "react-icons/io";

import SendMessageToGroup from '../../message/sendMessageToGroup';
import { FaReply } from 'react-icons/fa';
import ClassStudents from './classStudents';
import NewRoom from './newRoom';
import { NavLink } from 'react-router-dom';
import ShowUsers from '../../Teacher/ShowUsers';
import AddMeetingRoom from '../../Teacher/AddMeetingRoom';
import ShowMeetings from '../../Teacher/ShowMeetings';
import { HomePage } from '../../../translations/trans';
import SendReportToClass from './SendReportToClass';
import SendMsgToClass from './SendMsgToClass';
import EditClasspack from './EditClasspack';
import ShowMockClass from './ShowMockClass';
import SendReportToAllstd from './SendReportToAllstd';

function Classes() {
    const [status, setStatus] = useState(true);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Get_Classes());
    }, [dispatch]);

    const { classes, packages, user } = useSelector(({ classes, packages, loggedUser }) => ({
        classes, packages, user: loggedUser.user
    }));

    const getPackageByID = (id) => {
        const pack = packages.find(p => p._id === id);
        //  console.log('pack',pack)
        return pack
    }

    // Filter classes based on active/inactive status
    const filteredClasses = status ? classes.filter(cls => cls.active) : classes.filter(cls => !cls.active);


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    // Show Lessons For Class


    return (
        <div>
            <AddClass />
            <MenuSelection
                status={status}
                setStatus={setStatus}
                name1={`${HomePage.currentclasses[lang]}`}
                name2={`${HomePage.nonActiveClasses[lang]}`}
            />

            <div style={{ fontSize: "14px" }}>
                <Table responsive className='mt-5 text-center'>
                    <thead>
                        <tr>
                            <th>Num</th>
                            <th>{HomePage.class[lang]}</th>
                            <th>{HomePage.studyProgram[lang]}</th>
                            <th>{HomePage.studentLength[lang]}</th>
                            <th>{HomePage.sendMessage[lang]}</th>

                            <th>{HomePage.students[lang]}</th>
                            <th>{HomePage.createRoom[lang]}</th>
                            <th>{HomePage.mocks[lang]}</th>

                            <th>{HomePage.classSettings[lang]}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredClasses.map((cls, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{cls.name}</td>
                                <td>{packages && cls.packID && getPackageByID(cls.packID)?.name}</td>
                                <td>{cls.studentsIDS.length}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ background: "#4169E2" }} id="dropdown-basic">
                                            <IoIosSend />

                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item><SendMessageToGroup to={cls.studentsIDS} /></Dropdown.Item>
                                            <Dropdown.Item> <SendMsgToClass Class={cls} /></Dropdown.Item>
                                            <Dropdown.Item>  <SendReportToClass classId={cls?._id} /></Dropdown.Item>
                                            <Dropdown.Item>  <SendReportToAllstd packClass={cls} /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ background: "#4169E2" }} id="dropdown-basic">
                                            {HomePage.students[lang]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item><AddStudents Class={cls} /></Dropdown.Item>
                                            <Dropdown.Item> <ShowUsers Class={cls} /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ background: "#4169E2" }} id="dropdown-basic">
                                            {HomePage.createRoom[lang]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item><AddMeetingRoom packClass={cls} /></Dropdown.Item>
                                            <Dropdown.Item><ShowMeetings packClass={cls} /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td>
                                    <ShowMockClass classData={cls} />
                                </td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ background: "#4169E2" }} id="dropdown-basic">
                                            <IoSettings />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item> <DeactivateClass classId={cls._id} /></Dropdown.Item>
                                            <Dropdown.Item> <EditClasspack classId={cls._id} /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                {/* <td>
                                    <input
                                        type="datetime-local"
                                        style={{ border: "none", color: "#120C54" }}
                                        name="timeRoom"
                                        id="timeRoom"
                                        isutc={true}
                                    />
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Classes;
