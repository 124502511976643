import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FaReply } from 'react-icons/fa';
import serializeForm from 'form-serialize';
import { Send_Message_To_Group } from '../../actions/login&register';
import { HomePage } from '../../translations/trans';

function SendMessageToGroup({ to }) {
  const user = useSelector(state => state.loggedUser.user);
  const { role, _id: sender } = user;

  const [open, setOpen] = useState(false);
  const [alertVariant, setAlertVariant] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const formRef = useRef();
  const dispatch = useDispatch();

  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const message = serializeForm(formRef.current, { hash: true });
      const data = { message: message, to: to, sender: sender, type: role };
      const res = await dispatch(Send_Message_To_Group(data));
      setAlertVariant(res.case ? 'success' : 'danger');
      setAlertMessage(res.data.message);
    } catch (error) {
      setAlertVariant('danger');
      setAlertMessage('An error occurred while sending the message.');
      console.error(error);
    }
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  const [title, setTitle] = useState('');


  return (
    <>
      <div className='fcplus-cont'>
        <FaReply
          size="16px"
          onClick={handleShow}
          style={{ cursor: 'pointer', color: '#001C45' }}
        />
        {HomePage.sendMessage[lang]}
      </div>

      <Modal show={open} onHide={handleClose} dir={dir}>
        <Modal.Header closeButton>
          <Modal.Title>{HomePage.sendMessage[lang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
              <Form.Label>{HomePage.address[lang]}</Form.Label>
              <Form.Control type="text" name="title" placeholder="Enter title" defaultValue=""
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.stopPropagation();
                  }
                }} />
            </Form.Group>
            <Form.Group controlId="formContent">
              <Form.Label>{HomePage.content[lang]}</Form.Label>
              <Form.Control type="text" name="content" placeholder="Enter content" onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.stopPropagation();
                }
              }} />
            </Form.Group>
            <Button variant="primary" type="submit">{HomePage.send[lang]}</Button>
          </Form>
          {alertVariant && alertMessage &&
            <Alert variant={alertVariant} className="mt-3">
              {alertMessage}
            </Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='mt-3' onClick={handleClose}>{HomePage.close[lang]}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendMessageToGroup;
