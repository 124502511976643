import { getStudents, delStudent } from "../utils/API"
export const INETIALIZE_STUDENTS = 'INETIALIZE_STUDENTS'
export const UPDATE_STUDENTS = 'UPDATE_STUDENTS'
export const DELETE_STUDENTS = 'DELETE_STUDENTS'

const inetializeStudents = (students, totalDocs) => {
    return {
        type: INETIALIZE_STUDENTS,
        students,
        totalDocs
    }
}
// export const Inetialize_Students = (page = 1, limit = 4) => {
//     return (dispatch) => {
//         return getStudents(page, limit).then(res => {
//             console.log("rerererer", res?.data?.data?.paginationMeta)
//             dispatch(inetializeStudents(res.data.data, res?.data?.data?.paginationMeta?.totalPages));
//         });
//     };
// }
export const Inetialize_Students = (page = 1, limit = 4, searchField = '', search = '') => {
    return (dispatch) => {
        return getStudents(page, limit, searchField, search).then(res => {
            dispatch(inetializeStudents(res.data.data, res?.data?.data?.paginationMeta?.totalPages));
        });
    };
};

export const DELETE_STUDENT = "DELETE_STUDENT";
const deleteStudent = (id) => {
    return {
        type: DELETE_STUDENT,
        id
    }
}
export const Delete_Student = (id) => {
    return (dispatch) => {
        return delStudent(id).then(res => {
            dispatch(deleteStudent(id))
            alert(res.data)
        })
    }
}