import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { createRequest } from '../../../utils/packagesRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { Wallet_Number } from '../../../constants';
import fawry from "../../../assets/fawry.png"
import visa from "../../../assets/visa.png"
import card from "../../../assets/masterCard.png"
import { checkoutPayPal, subscripeToPackage } from '../../../utils/checkout';
import { HomePage } from '../../../translations/trans';
import { useSelector } from 'react-redux';
import { ErrorMsg } from '../../../utils/Toast';
import { getFilterClasses } from '../../../utils/class';

function Subscripe({ packData, user }) {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShowModal(false);

  const handleShow = () => {
    if (!user.auth) {
      alert('يجب تسجيل الدخول قبل الاشتراك');
      navigate('/login')
    }
    else {
      setShowModal(true);
    }
  }

  // const handleImageChange = (e) => setImage(e.target.files[0]);


  const [classItem, setClassItem] = useState(null)
  const [classesData, setClassesData] = useState([]);

  useEffect(() => {

    if (showModal) {
      const handleGetClasses = async () => {
        try {
          const res = await getFilterClasses(packData?._id);
          console.log(res);
          if (res?.data?.case) {
            setClassesData(res?.data?.classes);
          }
        } catch (e) {
          console.log(e)
        }
      }
      handleGetClasses();
    }


  }, [packData, showModal])








  const handleSubmit = async () => {
    try {
      const res = await checkoutPayPal({
        classId: classItem,
      }, packData?._id, user?.user?._id)

      console.log(res)

      if (res?.data?.case) {
        window.location.href = res?.data?.checkout?.link;
      }
    } catch (e) {
      console.log(e)
      ErrorMsg(e?.response?.data?.message)
    }
  }


  useEffect(() => {
    setTimeout(() => {
      setMessage(null)
    }, 5000)
  }, [message])
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  return (
    <div>
      <button className="login-register-btn header-call-us btn " onClick={handleShow}>
        {HomePage.subscribeNow[lang]}
      </button>


      <Modal show={showModal} onHide={handleClose} centered size='lg' dir={dir}>
        <Modal.Header>
          <Modal.Title>{packData?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <select className="form-control" onChange={(e) => setClassItem(e.target.value)}>
            <option hidden>Choose Class</option>
            {
              classesData?.map((classItem) => {
                return (
                  <option value={classItem?._id}>{classItem?.name}</option>
                )
              })
            }
          </select>

          {
            classItem ? (
              <div className='row justify-content-between align-items-center'>
                <div className='col-lg-4 col-md-6 col-sm-12 col-12 '>
                  <h3>{HomePage.Paymentgateway[lang]}</h3>
                  <p>{HomePage.ChoosePaymentgateway[lang]}</p>
                  <img src={fawry} alt="ImagedA" className='imagePayMent' />
                  <img src={visa} alt="ImageDA" className='imagePayMent' />
                  <img src={card} alt="ImageDA" className='imagePayMent' />
                </div>
                <div className='col-lg-7 col-md-6 col-sm-12 col-12'>
                  <p>{HomePage.finalPrice[lang]}</p>
                  {packData.discountPrice < packData.price ? (
                    <div>
                      <h3>
                        <del>{packData.price} {packData?.currency}</del> &nbsp;
                        <strong>{packData.discountPrice}  {packData?.currency}</strong>
                      </h3>
                    </div>
                  ) : (
                    <h3>{packData.price}  {packData?.currency}</h3>
                  )}
                </div>
              </div>
            ) : (<p>Should Select Class</p>)
          }



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {HomePage.cancel[lang]}
          </Button>
          {!loading ? <Button variant="primary" onClick={handleSubmit}>
            {HomePage.Confirmation[lang]}
          </Button> : <span>جارى الطلب ... </span>}
        </Modal.Footer>
      </Modal>

      {message && (
        <Alert variant={message.case ? 'success' : 'danger'} onClose={() => setMessage(null)} dismissible>
          <Alert.Heading>{message.case ? 'Success' : 'Error'}</Alert.Heading>
          <p>{message.message}</p>
        </Alert>
      )}
    </div>
  );
}

export default Subscripe;
