import React, { useEffect, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { getLevelById, urlBase } from '../../../utils/API';
import { getMaterialByCode } from '../../../utils/materials';
import CardCover from "../../../assets/programCover.png";
import Subscripe from './subscripe';
import { useSelector } from 'react-redux';
import NavButton from '../../styled-components/navButton';
import { NavLink } from 'react-router-dom';
import { HomePage } from '../../../translations/trans';

function PackageCard({ packageItem }) {
  const [level, setLevel] = useState('');
  const [material, setMaterial] = useState('');

  const fetchLevel = async (levelId) => {
    try {
      const res = await getLevelById({ _id: levelId });
      setLevel(res.data.level);
    } catch (err) {
      console.log(err.message);
    }
  }

  const fetchMaterial = async (code) => {
    try {
      // console.log('code', code)
      const res = await getMaterialByCode({ code });
      setMaterial(res.data.material);
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    try {
      if (packageItem) {
        fetchLevel(packageItem?.levelId);
        fetchMaterial(packageItem?.materials[0]?.materialCode);
      }
    }
    catch (err) {
      console.warn(err.message)
    }
  }, [packageItem]);


  const { user } = useSelector(({ loggedUser }) => {
    return { user: loggedUser }
  })

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  // console.log(packageItem)


  return (
    <Col key={packageItem._id} xs={12} sm={6} md={4} lg={3} xl={3}>
      <Card className="mb-3" style={{ height: '450px' }}> {/* Increased overall card height */}
        <Card.Body className="d-flex flex-column p-0"> {/* Removed padding */}
          <div style={{ height: '250px', overflow: 'hidden' }}> {/* Fixed height image container */}
            {packageItem?.coverImage !== null ? (
              <img
                src={`${urlBase}/${packageItem?.coverImage}`}
                alt="data"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <Card.Img
                src={CardCover}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </div>
          <div className="p-3 flex-grow-1 d-flex flex-column"> {/* Added padding and flex */}
            <div className="flex-grow-1">
              <Card.Title>{level?.name}</Card.Title>
              {/* <Card.Subtitle>{material?.name}</Card.Subtitle> */}
              {/* <Card.Title>{packageItem?.name}</Card.Title> */}
              {packageItem.discountPrice < packageItem.price ? (
                <Card.Subtitle style={{ color: 'red', paddingBottom: '10px' }}>
                  {HomePage.pricePackage[lang]}: <del>{packageItem?.price} {packageItem?.currency}</del> &nbsp;
                  <strong>{packageItem.discountPrice}{packageItem?.currency}</strong>
                </Card.Subtitle>
              ) : (
                <Card.Subtitle style={{ color: 'red', paddingBottom: '10px' }}>
                  {HomePage.pricePackage[lang]}: {packageItem?.price === 0 ? ` ${HomePage?.free[lang]}` : packageItem?.price + ` ${packageItem?.currency}`}
                </Card.Subtitle>
              )}
            </div>
            <div className="text-center text-center" style={{ margin: "20px auto" }}> {/* Pushed to bottom */}
              <Subscripe packData={packageItem} user={user} />
              <NavLink
                id="hero3"
                to={`/subscription/${packageItem._id}`}
                className="mt-2 header-call-us "
                style={{
                  textDecoration: "none",
                  padding: "8px",
                  border: "1px solid #1F3662",
                  color: "#1F3662",
                  width: "100%",
                  maxWidth: "250px"
                }}
              >
                {HomePage.details[lang]}
              </NavLink>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default PackageCard;