import React, { useEffect } from "react";
// import Contain from "../styled-components/contain";
// import Col from "../styled-components/col";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {Payment} from '../../utils/productApi.js'
import { Payment_Request } from "../../actions/payment.js";
// import Table2 from "../table/table2";
import { getDateXDays, getMonthAfter } from "../globalFunc";
import { Appointment_Confirm } from "../../actions/booking.js";
import InserCode from "../Admin/disscount/insertCode";
import { urlBase } from "../../utils/API.js";
import BlueHeader from "../publicPages/blueHeader.js";
import InviceIMG from "../../assets/confirm.png";
import { getdate } from "../globalFunc";
function Confirm() {
  const days = [
    { name: "Sunday" },
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
  ];

  const dispatch = useDispatch();
  // console.log(paymentTransaction)
  const [booking, paymentTransaction, user, teacher] =
    useSelector(mapStateToProps);
  // console.log('confirmm',booking)
  const amount = booking.price;
  const payReqData = {
    phone: user.phone,
    email: user.email,
    name: user.username,
    amount: booking.price,
    currency: booking.package.currency,
  };
  useEffect(() => {
    if (parseInt(amount) > 0) {
      dispatch(Payment_Request(payReqData));
    }
  }, [amount]);
  // const detailsPerDayArr = booking&&booking.booking.detailsPerDay;
  const daysOfWeekIndexes = booking && booking.packag.days;
  // const timesOfSessionsPerWeek = booking&& booking.package.days;
  // const startDate = detailsPerDayArr&&detailsPerDayArr[0].day;
  function details(booking) {
    const endDate = getMonthAfter(
      parseInt(booking.packag.period),
      new Date(booking.start)
    );
    return { endDate: endDate };
  }
  const detailsFetch = details(booking);
  //------------- Data will sent to server-------
  // object {packageName:'',subCategory:'',teacherId:'',studentId,startDate,endDate,days:[days indexes],times:[]};
  const appointmentData = {
    price: parseInt(booking.price),
    currency: booking.packag.currency,
    packageName: booking.packag.packag,
    subCategory: booking.packag.name,
    teacherId: booking.teacher,
    studentId: user._id,
    startDate: booking.start,
    endDate: booking.end,
    days: booking.package.days,
    discountCard: booking.disscountCode,
    disscount: booking.disscountAmount,
    period: booking.package.period,
    session: booking.package.session,
  };

  // console.log(appointmentData);
  //------confirmation--------
  const navigate = useNavigate();

  const onConfirm = (obj) => {
    if (obj.price == 0) {
      dispatch(Appointment_Confirm(obj));
      navigate("/booking/thanks");
    } else {
      const paymentLink = paymentTransaction.transaction.url;
      window.location.href = paymentLink;
    }
  };

  const AmPm = (d) => {
    const dd = parseInt(d);
    if (dd > 12) {
      return d - 12 + " PM";
    } else {
      return d + " AM";
    }
  };
  const getDateFormat = (date) => {
    const day = date.getDate();
    const mon = date.getMonth();
    const year = date.getFullYear();
    return day + "/" + mon + "/" + year;
  };
  const startDate = new Date(booking.start);
  const endDate = booking.end;
  // console.log(getDateFormat(startDate))
  return (
    <>
      <BlueHeader title="Payment" />
      <div className="pay-body">
        <div className="pay-disscount">
          <InserCode urlBase={urlBase} />
        </div>
        <section className="pay-det">
          <div className="pay-det-bar" style={{ backgroundColor: "#283476" }}>
            <span id="inv3">Order details</span>
            <span id="inv4">total</span>
          </div>
          <div className="pay-det-data">
            <div className="pay-det-data1">
              <div className="pay-det-data-row">
                <span id="inv5" className="pay-det-data-row-id">
                  Session per week:
                </span>
                <span className="pay-det-data-row-value" id="inv6">
                  {parseInt(booking.packag.price) !== 0
                    ? booking.packag.days.length
                    : "Terial session"}
                </span>
              </div>
              <div className="pay-det-data-row">
                <span id="inv7" className="pay-det-data-row-id">
                  time details:
                </span>
                <span className="pay-det-data-row-value" id="inv8">
                  <br />
                  <span id="confstda">start date: </span>{" "}
                  {getDateFormat(startDate)}
                  -<br /> <span id="confendat"> end date: </span>
                  {getDateFormat(endDate)}
                  -<br /> <span id="confsesstim"> Session time: </span>{" "}
                  {AmPm(new Date(booking.start).getHours())}
                </span>
              </div>
              <div className="pay-det-data-row">
                <span id="inv9" className="pay-det-data-row-id">
                  teacher:
                </span>
                <span className="pay-det-data-row-value" id="inv10">
                  {teacher.username}
                </span>
              </div>
              <div className="pay-det-data-row">
                <span className="pay-det-data-row-id" id="inv11">
                  Price:
                </span>
                <span className="pay-det-data-row-value" id="inv12">
                  {booking.packag.price} <span id="currency-sympol">RSA</span>
                </span>
              </div>
            </div>

            <img
              src={teacher.personalImage ? teacher.personalImage : InviceIMG}
              alt=""
            />
            <span id="inv13">
              {booking.package.price} <span id="currency-sympoll">RSA</span>
            </span>
          </div>
          <div className="pay-det-bar" style={{ backgroundColor: "#73747D" }}>
            <span style={{ width: "70%" }} id="inv14">
              total
            </span>
            <span id="inv15" style={{ width: "30%" }}>
              {booking.price}
              <span id="currency-sympol1">RSA</span>
            </span>
          </div>
          <div
            className="pay-det-bar"
            style={{ backgroundColor: "#fff", color: "#656565" }}
          >
            <span style={{ width: "70%" }} id="inv16">
              total
            </span>
            <span style={{ width: "30%" }} id="inv17">
              {booking.price}
              <span id="currency-sympol2">RSA</span>
            </span>
          </div>
        </section>
        <div className="book-sub-cont">
          <button
            className="book-submit-btn"
            onClick={() => onConfirm(appointmentData)}
            disabled={false}
            id="bookconfirmbtn"
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}
function mapStateToProps({ booking, payment, loggedUser, users }) {
  const bookingData = booking;
  // console.log(users.teachers,booking)
  const teacher = users.teachers.filter((t) => t._id == booking.teacher);

  return [bookingData, payment.res, loggedUser.user, ...teacher];
}
export default Confirm;
