import React, { useState } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { MdAttachment } from "react-icons/md";
import { getAnswerStudent, uploadMockStdAnswer } from '../../utils/mock';
import axios from 'axios';
import { ErrorMsg, success } from '../../utils/Toast';
import { urlBase } from '../../utils/API';
import { HomePage } from '../../translations/trans';

const AddAnswerMock = ({ singleMock }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const [loading, setLoading] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const loggedUser = useSelector((state) => state.loggedUser);
    const [comment, setComment] = useState("")


    // Upload File Locally
    const uploadingFileLocally = async (data) => {
        const formData = new FormData();
        formData.append("file", data);

        try {
            setFileUploading(true);
            const response = await axios({
                method: "POST",
                url: `${urlBase}/api/upload-file-locally`,
                data: formData,
            });
            return response.data; // Assuming this contains { name: "uploadedFileName.pdf" }
        } catch (error) {
            console.error(error);
            ErrorMsg('File upload failed');
            return null;
        } finally {
            setFileUploading(false);
        }
    };

    // Handle file change and upload
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const uploadResult = await uploadingFileLocally(selectedFile);
            if (uploadResult && uploadResult?.data) {
                setFile(uploadResult?.data?.name);  // Set the file name in state
                // SuccessMsg('File uploaded successfully');
            }
        }
    };


    const uploadMockAnswer = async () => {
        try {
            setLoading(true);
            const res = await uploadMockStdAnswer({
                file: file,
                comment: comment
            }, singleMock?._id, loggedUser?.user?._id)
            if (res?.data?.case) {
                success(res?.data?.message)
                handleClose();

            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false);

        }
    }



    return (
        <div>
            <button className='btn' style={{ backgroundColor: "#1F3662", color: "white" }}
                onClick={handleShow}
            >
                {HomePage.uploadAnswer[lang]} <MdAttachment />

            </button>
            <Modal show={show} onHide={handleClose} centered className='text-center' dir={dir}>

                <Modal.Body>
                    <input
                        type="file"
                        name="filePdf"
                        id="filePdf"
                        accept="application/pdf"
                        className='form-control'
                        onChange={handleFileChange}
                    />

                    <div className='mt-4'>
                        <textarea className='form-control' name="answer" id="answer"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{ width: "100%", resize: "none" }}

                            placeholder='Enter Your Answer'>

                        </textarea>
                    </div>
                    {fileUploading && <Spinner animation="border" role="status" />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={uploadMockAnswer} disabled={loading || fileUploading}>
                        {loading ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : HomePage.add[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddAnswerMock
