import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { HomePage } from '../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRecManually } from '../../utils/API';
import { handleDelSessionManually } from '../../actions/sessions';
import { success } from '../../utils/Toast';

const DeleteRecordManually = ({ classData
    , session, recordingCode }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => { setShow(true) }
    const handleClose = () => { setShow(false) }
    const [loading, setLoading] = useState(false)

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    const handleDelSessionManuallyData = async () => {
        try {
            setLoading(true)
            const res = await dispatch(handleDelSessionManually(
                {
                    classId: classData?._id,
                    sessionId: session?._id,
                    recordingCode: recordingCode
                }, loggedUser?.user?._id
            ))
            if (res?.data?.case) {
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <button onClick={handleShow} className='btn mt-5' style={{ color: "white", background: "#4169E2" }} rel="noreferrer"  >
                Delete Record
            </button>
            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Body>
                    <p>Did You Sure From Delete Record?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger'>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button onClick={handleDelSessionManuallyData} disabled={loading}>
                        {loading ? 'loading..' : HomePage.delete[lang]}
                        {/* {HomePage.delete[lang]} */}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteRecordManually
