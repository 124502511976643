import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FaReply } from 'react-icons/fa';
import serializeForm from 'form-serialize';
import { Send_Message } from '../../actions/login&register';
import { HomePage } from '../../translations/trans';

function SendMessage({ to }) {
  const user = useSelector(state => state.loggedUser.user);
  const { role, _id: sender } = user;

  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const formRef = useRef();
  const dispatch = useDispatch();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = serializeForm(e.target, { hash: true });
    const messageData = {
      message: formData,
      to: to,
      sender: sender,
      type: role,
      date: new Date().toISOString()
    };
    try {
      const res = await dispatch(Send_Message(messageData));
      if (res.data.case) {
        setAlertVariant('success');
        setAlertMessage(res.data.message);
      } else {
        setAlertVariant('danger');
        setAlertMessage(res.data.message);
      }
    } catch (error) {
      setAlertVariant('danger');
      setAlertMessage('An error occurred while sending the message.');
    }
    // handleClose();
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });


  return (
    <>
      {/* <FaReply size="2em" onClick={handleShow} style={{ color: 'green', cursor: 'pointer' }} /> */}
      <FaReply
        size="2em" onClick={handleShow}
        style={{ cursor: 'pointer', color: '#001C45' }}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{HomePage.sendMessage[lang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
              <Form.Label>{HomePage.address[lang]}</Form.Label>
              <Form.Control type="text" name="title" placeholder="Enter title"
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.stopPropagation();
                  }
                }}

              />
            </Form.Group>
            <Form.Group controlId="formContent">
              <Form.Label>{HomePage.content[lang]}</Form.Label>
              <Form.Control type="text" name="content" placeholder="Enter content"
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.stopPropagation();
                  }
                }}

              />
            </Form.Group>
            <Button variant="primary" type="submit">ارسال</Button>
          </Form>
          {alertVariant && alertMessage &&
            <Alert variant={alertVariant} className="mt-3">
              {alertMessage}
            </Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {HomePage.close[lang]}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendMessage;
