import React, { useState } from 'react'
import ModalAddMaterial from './ModalAddMaterial'
import ModalEdit from './ModalEdit'
import ModalDelete from './ModalDelete'

import ShowMateriaLevel from './ShowMateriaLevel'

function Level({ item, materials }) {
  const [message, setMessage] = useState(null)
  return (
    <React.Fragment >
      <tr style={{ cursor: 'pointer' }}>
        <td>{item.name}</td>
        <td>
          <ModalAddMaterial
            //   handleShowAddMaterialModal={handleShowAddMaterialModal}
            levelId={item._id}
            materialIDS={item.materialIDS}
            //   handleCloseAddMaterialModal={handleCloseAddMaterialModal}
            //   showAddMaterialModal={showAddMaterialModal} // Set to false to prevent automatic modal opening
            //   setMessage={setMessage}
            materials={materials}
          />
        </td>
        <td>
          {item?.branch === "AC" ? ("Cambridge"):(null)}
          {item?.branch === "AE" ? ("Edexcel"):(null)}
        </td>

        <td>
          <div className="d-flex justify-content-around">
            <ModalEdit item={item} setMessage={setMessage} />
            <ModalDelete id={item._id} setMessage={setMessage} />
            <ShowMateriaLevel item={item} setMessage={setMessage} />
            {/* <GrView onClick={() => toggleRow(item._id)} size="25px" color="#fff" /> */}
          </div>
        </td>
      </tr>
      {/* {expandedRows.includes(item._id) && (
      <tr>
        <td colSpan="3">
          <ExpandedContent materials={materials.filter((material) => item.materialIDS.includes(material._id))} />
        </td>
      </tr>
    )} */}
    </React.Fragment>
  )
}

export default Level