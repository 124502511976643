import {
  REGISTER_TEACHER,
  REGISTER_STUDENT,
  REGISTER_ADMIN,
  INETIALIZE_ADS,

} from "../actions/login&register";
import { INETIALIZE_STUDENTS, DELETE_STUDENT } from "../actions/students"
import { INETIALIZE_TEACHERS, SET_SCHEDUAL, UPDATE_TEACHER, DELETE_TEACHER } from "../actions/teachers";
export const users = (state = { teachers: [], students: [], totalDocs: 0 }, action) => {
  switch (action.type) {
    case REGISTER_TEACHER:
      return state = { ...state, teachers: [...state.teachers, action.user] };
    case DELETE_TEACHER:
      return state = { ...state, teachers: state.teachers.filter(st => st._id !== action.id) }

    case REGISTER_STUDENT:
      return state = { ...state, students: [...state.students, action.user] };
    case DELETE_STUDENT:
      return state = { ...state, students: state.students.filter(st => st._id !== action.id) }
    case INETIALIZE_STUDENTS:
    return {
      ...state,
      teachers: action.students,
      totalDocs: action.totalDocs
    }
    case INETIALIZE_TEACHERS:
      return {
        ...state,
        teachers: action.teachers,
        totalDocs: action.totalDocs
      }
    case SET_SCHEDUAL:
      const newTeachers = state.teachers.map(t => { if (t.email === action.id) { return t.schedual = action.schedual } })
      return state = { ...state, teachers: newTeachers }
    case UPDATE_TEACHER:
      return state = {
        ...state, teachers: state.teachers.map((t) => {
          if (t._id === action.id) return action.teacher;
          else return t
        })
      }

    default: return state
  }
};
export const admins = (state = [], action) => {
  switch (action.type) {
    case REGISTER_ADMIN:
      return (state = [...state, action.user])
    case INETIALIZE_ADS:
      return state = action.ads
    default: return state
  }
};
