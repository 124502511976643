import React, { useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Choose_Teacher } from "../../actions/booking";
// import { Inetialize_Teachers } from "../../actions/teachers";
import Col from '../styled-components/col';
import Contain from '../styled-components/contain';
// import NavButton from "../styled-components/navButton";
// import VideoPlayer from "../videoPlayer";
import "./booking.css"


function ChooseTeacher() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(()=>{
    // dispatch(Inetialize_Teachers())
  },[])
  const {teachers} = useSelector(mapStateToProps)
  const onClick = (teacher)=>{
    try{
      dispatch(Choose_Teacher(teacher._id))
      navigate('/booking/choose-date')
    }catch(err){
      console.log(err)
    }
  }
  return (
    <>
      <h1 className="choose-teacher-title"> Choose Teacher</h1>
     <Contain perfix='booking-container' >
      {teachers.length?teachers.map((t,i)=><Teacher key={i} user={t} index={i} onClick={onClick}/>):<Col></Col>}
     </Contain>
    
     
    
    </>
  );
  
}
function Teacher({user,index,onClick}){
    const imageUrl =
    user.personalImage ? user.personalImage:'';
 
    return(
        <>
        <div className="teacher-image" onClick={()=>onClick(user)}>
                    <h5>{user.username}</h5>
            
                    <img src={imageUrl} alt={user.username} />
                  
         
        </div>
      </>
    )
}
function mapStateToProps({users}){
   const teachers = users.teachers.filter(t => t.active===true)
   return {teachers}
}
export default ChooseTeacher