import axios from 'axios';
import { urlBase } from './API';

// create Mock
export const addMock = (data, id, userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/materials/${id}/mocks?userId=${userId}`,
        data: data
    })
}


// Get Mock
export const getMocksApi = (materialId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/mocks?materialId=${materialId}`,
    })
}


// Update Single Mock
export const updateSingleMock = (data, id, userId) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/mocks/${id}?userId=${userId}`,
        data: data
    })
}



// Delete Mock

export const deleteMockApi = (id, userId) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/mocks/${id}?userId=${userId}`,
    })
}



// Std Upload Answer Mock
export const uploadMockStdAnswer = (data,id,userId)=>{

    return axios({
        method:"POST",
        url:`${urlBase}/api/mocks/${id}/mock-answers/me?userId=${userId}`,
        data:data
    })
}


// Get Answers Students

export const getAnswerStudent = (idMock,userId)=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/mock-answers/me?mockId=${idMock}&userId=${userId}`
    })
}

// 
export const submitAnswerStudent = (userId,mockId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/mock-answers/${mockId}/submit/me?userId=${userId}`
    })
}



// Get Mocks and answers for std

export const getAnswersMock = (data,id,userId)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/users/${id}/mocks?userId=${userId}`,
        data:data
    })
}


// Get Change State
export const changeState = async(data,answerId,userId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/mock-answers/${answerId}/state/admin?userId=${userId}`,
        data:data
    })
}


// Get Change Mark
export const changeMark = async(data,answerId,userId)=>{
    return axios({
        method: 'PATCH',
        url:`${urlBase}/api/mock-answers/${answerId}/marks/admin?userId=${userId}`,
        data:data
    })
}


// Get Mocks For Std
export const handleMockForStd = async(matCode,userId)=>{

    return axios({
        method:"POST",
        // url:`${urlBase}/api/mocks/student/custom?materialCode=${matCode}&userId=${userId}`
        url:`${urlBase}/api/mocks/student/custom?userId=${userId}`,
        data:{
            materialCode:matCode
        }
    })
}



// Add Answer To Mock
export const handleAddAnswer = (data,id,userId)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/mocks-answers/${id}/answer?userId=${userId}`,
        data:data
    })
}