import React, { useEffect, useState } from 'react';
import { FaUsers } from "react-icons/fa";
import { Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SendMessage from '../message/sendMessage';
import ChooseReport from './ChooseReport';
import { removeStudentsFromClass } from '../../utils/class';
import { ErrorMsg, success } from '../../utils/Toast';
import { HomePage } from '../../translations/trans';
import DeleteStudent from './DeleteStudent';
import { BsWhatsapp } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import SendWhatsappMsg from './SendWhatsappMsg';
import GetLessonId from '../shared/GetLessonId';
import ModalTestResult from '../Admin/classControl/ModalTestResult';
import Dropdown from 'react-bootstrap/Dropdown';
import MocksModal from './MocksModal';
import ReportForAdmin from './ReportForAdmin';
import { getStudentsByIDS } from '../../utils/API';

const ShowUsers = ({ Class }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [lesson, setLesson] = useState(null);
    const [packageData, setPackageData] = useState(null);

    const [selectedStudents, setSelectedStudents] = useState([]); // State to store selected student IDs

    const { students } = useSelector(state => ({
        students: state.users.students,
    }));
console.log({students})
    const [classStudents, setClassStudents] = useState([]);


    const [classDataforMock, setClassDataforMocks] = useState(null)
    const [studnetDataforMock, setStudentDataForMock] = useState(null)

    const fetchStudents =async (updatedClass = null) => {
        try{
        // const IDS = Class.studentsIDS;
        const IDS = updatedClass ? updatedClass.studentsIDS : Class.studentsIDS;
        const res = await getStudentsByIDS({IDS});
        if(res?.data?.students){
            setClassStudents(res?.data?.students);
        }
        else{
            setClassStudents([])
        }
    }
    catch(err){
        console.warn(err)

    }
    }

    useEffect(() => {
        fetchStudents();
    }, [Class, students]);


    // Function to handle selection of students
    const handleStudentSelect = (studentId) => {
        setSelectedStudents((prevSelected) => {
            if (prevSelected.includes(studentId)) {
                return prevSelected.filter(id => id !== studentId); // Deselect if already selected
            } else {
                return [...prevSelected, studentId]; // Add to selected IDs
            }
        });
    };

    // Function to select all students
    const handleSelectAllStudents = () => {
        if (selectedStudents.length === classStudents.length) {
            // If all are selected, deselect all
            setSelectedStudents([]);
        } else {
            // Otherwise, select all
            const allStudentIds = classStudents.map(student => student._id);
            setSelectedStudents(allStudentIds);
        }
    };

    // Function to remove students to API
    const handleRemoveStudentsFromClasses = async () => {
        try {
            const res = await removeStudentsFromClass({
                id: Class?._id,
                students: selectedStudents
            });
            if (res?.data?.case) {
                success(res?.data?.message);
                fetchStudents(res?.data?.class)
            }
        } catch (e) {
            if (e?.response) {
                ErrorMsg(`هناك مشكله ما`)
            }
        }
    };

    // For Another Modal Open And Close Current Modal

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMockModal, setshowMockModal] = useState(false);
    const [whatsApp, setWhatsAppModal] = useState(false);
    const [deleteStudentId, setDeleteStudentId] = useState(null);
    const [student, setStudent] = useState(null);

    const handleDeleteModalClose = () => setShowDeleteModal(false);
    const handleWhatsModalClose = () => setWhatsAppModal(false);
    const handleMockClose = () => setshowMockModal(false);

    const handleDeleteModalShow = (studentId) => {
        setDeleteStudentId(studentId);
        setShowDeleteModal(true);
        handleClose();
    };

    const handleWhatsAppModal = (studentId) => {
        setStudent(studentId);
        setWhatsAppModal(true);
        handleClose();
    };

    const handleMock = (std) => {
        setClassDataforMocks(Class)
        setStudentDataForMock(std)
        setshowMockModal(true)
        handleClose();
    }


    // For Lesson Classes
    const { packages } = useSelector(({ packages }) => ({
        packages
    }));

    const fetchPackage = async () => {
        const pack = packages.find(p => p._id === Class.packID);
        setPackageData(pack);
    };

    useEffect(() => {
        fetchPackage();
    }, [Class, packages]);


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            {/* Modal Student */}
            <FaUsers onClick={handleShow} size={20} color="#001C45" />
            <Modal show={show} onHide={handleClose} size="xl" centered dir={dir}>
                <div className="d-flex justify-content-start">
                    {
                        selectedStudents?.length > 0 ? (
                            <button className="btn w-25 mt-3 mx-2"
                                style={{ background: "#4169E2" }} onClick={handleRemoveStudentsFromClasses}>
                                {HomePage.selectedStudentsDel[lang]}
                            </button>
                        ) : (
                            <button className="btn w-25 mt-3 mx-2"
                                style={{ background: "#4169E2" }} disabled>
                                {HomePage.selectedStudentsDel[lang]}
                            </button>
                        )
                    }
                    {/* New Button to Select All Students */}
                    <button className="btn w-25 mt-3 mx-2"
                        style={{ background: "#4169E2" }} onClick={handleSelectAllStudents}>
                        {selectedStudents.length === classStudents.length ? `${HomePage.selectAllStdCancel[lang]}` : `${HomePage.selectAllStd[lang]}`}
                    </button>
                </div>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th></th>
                                <th>{HomePage.nameForm[lang]}</th>
                                <th>{HomePage.email[lang]}</th>
                                <th>{HomePage.sendMessage[lang]}</th>
                                <th>{HomePage.reports[lang]}</th>
                                {/* <th>{HomePage.whatsApp[lang]}</th> */}
                                <th>{HomePage.showResultsLesson[lang]}</th>
                                <th>{HomePage.mocks[lang]}</th>
                                <th>{HomePage.delete[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                classStudents.map((student, index) => (
                                    <tr key={student._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <input
                                                type="checkbox" // Changed to checkbox
                                                name={`selectStudent${index}`}
                                                checked={selectedStudents.includes(student._id)}
                                                onChange={() => handleStudentSelect(student._id)}
                                            />
                                        </td>
                                        <td>{student.name ? student.name : student.username}</td>
                                        <td>{student.email}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle style={{ background: "#1F3662" }} id="dropdown-basic">
                                                    {HomePage.sendMessage[lang]}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>

                                                    <Dropdown.Item >
                                                        <SendMessage to={student._id} />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item >
                                                        <BsWhatsapp color={"#25D366"} size={"25"} onClick={() => handleWhatsAppModal(student._id)} />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item >
                                                        <ChooseReport classId={Class._id} studentId={student._id} />
                                                    </Dropdown.Item>


                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                        <td>
                                            <ReportForAdmin std={student} classData={Class} />
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle style={{ background: "#1F3662" }} id="dropdown-basic">
                                                    {HomePage.lessonName[lang]}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {packageData?.selectedLessons.map((lesson) => (
                                                        <Dropdown.Item key={lesson} value={lesson}>
                                                            <ModalTestResult lessonId={lesson} userId={student?._id} name={student.name} />
                                                        </Dropdown.Item>

                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                        {/* <td><ChooseReport classId={Class._id} studentId={student._id} /></td> */}
                                        <td>
                                            <button className='btn' style={{ background: "#1F3662", color: "white" }} onClick={() => {
                                                handleMock(student)
                                            }}>{HomePage.mocks[lang]}</button>
                                        </td>
                                        <td>
                                            <MdDelete onClick={() => handleDeleteModalShow(student._id)}
                                                style={{ color: "#D43B43" }} size={"25"} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <div>
                <DeleteStudent
                    show={showDeleteModal}
                    handleClose={handleDeleteModalClose}
                    Class={Class}
                    studentId={deleteStudentId}
                    onDeleteSuccess={fetchStudents}
                />
            </div>
            <div>
                <SendWhatsappMsg
                    show={whatsApp}
                    handleClose={handleWhatsModalClose}
                    Class={Class}
                    studentId={student}
                />
            </div>
            <div>
                <MocksModal
                    show={showMockModal}
                    handleClose={handleMockClose}
                    classData={classDataforMock}
                    stdData={studnetDataforMock}

                />
            </div>
        </div>
    );
};

export default ShowUsers;
