import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { urlBase } from '../../../utils/API';
import { Get_All_Packages, Update_Package } from '../../../actions/packages';
import { success } from '../../../utils/Toast';
import axios from "axios";
import { HomePage } from '../../../translations/trans';

const EditDetails = ({ pack }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const [namePackage, setPackageName] = useState(pack?.name);
    const [pricePackage, setPackagePrice] = useState(pack?.price);
    const [discountpricePackage, setPackageDiscountPrice] = useState(pack?.discountPrice);
    const [coverImage, setCoverImage] = useState(pack?.coverImage);
    const [curr, setCurr] = useState(pack?.currency);

    const dispatch = useDispatch();

    const handlePackage = async () => {
        try {

            const res = await dispatch(Update_Package({ ...pack, name: namePackage, price: pricePackage, discountPrice: discountpricePackage, currency: curr, id: pack._id }));
            success(res?.data?.message);
            handleClose();
        } catch (e) {
            console.log(e);
            dispatch(Get_All_Packages())
            handleClose();
        }
    };
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <Button style={{ backgroundColor: '#fff', color: 'gray' }} onClick={handleShow}>{HomePage.editDetails[lang]}</Button>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Header closeButton>
                    <Modal.Title>{HomePage.editDetails[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>{HomePage.courseName[lang]}</label>
                        <input
                            type="text"
                            name="namePackage"
                            className="form-control w-100"
                            id="namePackage"
                            value={namePackage}
                            onChange={(e) => setPackageName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>{HomePage.coursePrice[lang]}</label>
                        <input
                            type="number"
                            name="number"
                            className="form-control w-100"
                            id="namePackage"
                            value={pricePackage}
                            onChange={(e) => setPackagePrice(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>{HomePage.courseDiscount[lang]}</label>
                        <input
                            type="number"
                            name="namePackage"
                            className="form-control w-100"
                            id="namePackage"
                            value={discountpricePackage}
                            onChange={(e) => setPackageDiscountPrice(e.target.value)}
                        />
                    </div>

                    <div>
                        <label>{HomePage.chooseCurrency[lang]}</label>
                        <select className="form-control" value={curr} onChange={(e) => setCurr(e.target.value)}>
                            <option value={"USD"}>USD</option>
                            <option value="EUR">EUR</option>
                        </select>
                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="primary" onClick={handlePackage}>
                        {HomePage.saveChanges[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EditDetails;
