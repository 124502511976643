import React, { useEffect, useState } from 'react';
import imageSuccess from "../../assets/success.png"
import Footer from '../Home/footer'
import Header from '../Home/header'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { paymentExecuse } from '../../utils/checkout';
import { ErrorMsg, success } from '../../utils/Toast';
import { useSelector } from 'react-redux';
const Success = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const paymentId = queryParams.get('paymentId');
    const token = queryParams.get('token');
    const payerId = queryParams.get('PayerID');
    const loggedUser = useSelector((state) => state.loggedUser)

    useEffect(() => {
        if (paymentId && token && payerId) {
            const data = {
                paymentId,
                payerId
            }
            const handleExecutePayment = async () => {
                try {
                    const res = await paymentExecuse(data, loggedUser?.user?._id);
                    if (res?.data?.case) {
                        success(res?.data?.message)
                    }
                } catch (e) {
                    ErrorMsg(e?.response?.data?.message)
                }
            }

            handleExecutePayment();
        }

    }, [])

    return (
        <div>
            <Header />
            <div className='text-center'>
                <img src={imageSuccess} alt="success" />
                <p style={{ color: "#0E356E" }}>تم الاشتراك بنجاح</p>

                <div>
                    <Link to="/subscription" className='btn' style={{ background: "#15345E", color: "white", textAlign: "center" }}>الاشتراكات</Link>
                    <br />
                    <Link to="/" className='btn mt-3 mb-5' style={{ border: "0.5px solid #15345E", color: "black" }}>الذهاب الي الصفحه الرئيسيه</Link>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Success
