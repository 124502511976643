import React from 'react'
import { deleteTest } from '../../../utils/tests';
import { success } from '../../../utils/Toast';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ModalDeleteExam = ({ idTest }) => {
    // For Modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const loggedUser = useSelector((state) => state.loggedUser);




    const deleteTestFunc = async () => {
        try {
            const res = await deleteTest({ _id: idTest });
            if (res?.data?.case) {
                success(res?.data?.message)
                navigate(`/user/${loggedUser?.user?._id}/materials`)
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div>
            <button className="btn bg-danger" onClick={handleShow}>Delete Exam </button>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <Modal.Title>حذف الامتحان</Modal.Title>
                </Modal.Header>
                <Modal.Body>هل انت متاكد من حذف الامتحان</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        الغاء
                    </Button>
                    <Button variant="primary" onClick={deleteTestFunc}>
                        تاكيد
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDeleteExam
