import React,{useEffect,useState} from 'react'
import axios from 'axios';
function DisscountList({urlBase}) {
    const [codes,setCodes] = useState([])
    const [message ,setMassage] = useState('')
     const getList = () => {
        return axios({
          method: "get",
          url: `${urlBase}/api/collect/code`,
        });
      };
      useEffect(()=>{
        getList().then(res=>{
            setCodes(res.data)
        })
      },[message])
      const stopCode = (data)=>{
    const url = `${urlBase}/api/stop/code`;
         axios({
            method: "POST",
            data: data,
            withCredentials: true,
            url: url,
          }).then(res=>{ 
            setMassage(res.data)
            setTimeout(() => {
               setMassage('') 
            }, 5000);
        })
      }
  return (
    
    <ul className='disscountlist'>
        <h1 id='disslist'>DissCount codes List</h1>
        <p >
            {message}
        </p>
    {codes?codes.map((c,i)=><li key={i}>
        <div className='code-row'>
            <span>{c.code}</span>
            <span>{c.amount}{c.type==='ratio'?' %':' RSA'}</span>
            <span>{c.creationDate.split('T')[0]}</span>
            <span><button onClick={()=>{
           stopCode(c);
            }}>
                stop
                </button></span>
        </div>
    </li>):<p>No codes yet</p>}
    </ul>
    
  )
}

export default DisscountList