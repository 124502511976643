import React from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../../translations/trans';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
// import { urlBase } from '../../../utils/API';
const ShowFileMock = ({ mockFile }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                {HomePage.showMocks[lang]}
            </Button>
            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Body>
                    <iframe src={`${mockFile}`} width="100%" height="500px" title="Mock File" />
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ShowFileMock
