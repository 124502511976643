import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import AddAnswerMock from './AddAnswerMock';
import { submitAnswerStudent } from '../../utils/mock';
import { ErrorMsg, success } from '../../utils/Toast';
import { HomePage } from '../../translations/trans';
const SubmitAnswer = ({ show, handleClose, singleMock }) => {
    const { lang, dir, user } = useSelector(({ language, loggedUser }) => {
        return { lang: language.lang, dir: language.dir, user: loggedUser?.user };
    });

    // const [loading, setLoading] = useState(false)

    // const submitMockAnswer = async (userId, mockId) => {
    //     try {
    //         setLoading(true)
    //         const res = await submitAnswerStudent(userId, mockId);
    //         if (res?.data?.case) {
    //             success(res?.data?.message);
    //         }
    //         else {
    //             ErrorMsg(res?.data?.message)
    //         }
    //         setLoading(false)
    //     }
    //     catch (err) {
    //         console.warn(err?.message);
    //         ErrorMsg(err?.response?.data?.message);
    //         setLoading(false)
    //     }

    // }
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered className='text-center' dir={dir}>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>

                                <th>{HomePage.mocks[lang]}</th>

                                <th>{HomePage.addAnswer[lang]}</th>
                                {/* <th>Submit</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>{singleMock?.name}</td>

                                <td>
                                    <AddAnswerMock singleMock={singleMock} />
                                </td>
                                {/* <td>
                                    <button className="btn"
                                        onClick={() => { submitMockAnswer(user?._id, singleMock?._id) }}
                                        disabled={loading}
                                        style={{ backgroundColor: "#6B8F04", color: "white" }}
                                    >
                                        {loading ? "loading..." : "Submit"}
                                    </button>
                                </td> */}
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SubmitAnswer
