import axios from 'axios';
import { urlBase } from './API';

// create new class 
export const newClass = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/create-new-class`,
        data: data //{name , levelId, branchId}
    })
}

// add students 
export const addStudentsToClass = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/add-students-class`,
        data: data// {students,id}
    })
}
// change class active state
export const changeClassActiveState = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/deactivate-class`,
        data: data // {id,active}
    })
}
// get classes 
export const getClasses = () => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/get-classes`,

    })
}
//get student classes 

export const getClassesByStudent = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/get-classes-by-student`,
        data: data // {studentId,packID}
    })
}

// create meeting room
//{classId}
export const createMeetingRoom = (data) => {
    return axios({
        method: 'post',
        url: `${urlBase}/api/classes/${data.classId}/teachers`,
        data: data
    })
}



// Remove Students From Class
export const removeStudentsFromClass = (data) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/students-class`,
        data: data
    })
}


// Send Customized message Whats app
export const sendMessageWhatsApp = (data, userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/send-customized-whatsapp-message?userId=${userId}`,
        data: data
    })
}


// Change Package Class 
export const changePack = (data) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/change-class-package`,
        data: data
    })
}


// Add Recording manually

export const addRecordManually = (data, userId) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/classes-sessions-manual-recordings?userId=${userId}`,
        data: data
    })
}


// Get Report For Std

export const getReportForStd = async (data, classId,
    studentId,
    userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/classes/${classId}/students/${studentId}/report?userId=${userId}`,
        data: data
    })
}

// Get Mocks Per Class

export const getMocksPerClass = async (id) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/classes:/${id}/mocks`
    })
}


// Get Report Mock 
export const getReportMock = async (mockId, classId, userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/classes/${classId}/mocks/${mockId}/report?userId=${userId}`
    })
}

// Get Filter Classes
export const getFilterClasses = async(packId)=>{
    return axios ({
        method:"GET",
        url:`${urlBase}/api/get-classes?packID=${packId}`
    })
}