import React, { useEffect, useState } from "react";
import { OLP_Logo as Logo, platformName } from "../../constants.js";
import "./header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavButton from "../styled-components/navButton";
import { Log_Out } from "../../actions/login&register";
import Button from "../styled-components/button";
import { AiOutlineMenu } from 'react-icons/ai';
import SideNave from './mobileMenu.js';
import { HomePage } from "../../translations/trans.js";
import { FaEarthAmericas } from "react-icons/fa6";
import { setLang } from "../../actions/language.js";

function Header({ menuColor }) {
  const user = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    dispatch(Log_Out());
    // navigate("/");
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  // Declare page arrays inside the Header component where lang is defined
  const publicPages = [
    { name: `${HomePage.home[lang]}`, to: "/" },
    { name: `${HomePage.studyProgram[lang]}`, to: "/subscription" },
    { name: `${HomePage.aboutUs[lang]}`, to: "/about" },
  ];

  const studentPages = [
    { name: `${HomePage.home[lang]}`, to: "/" },
    // { name: "الحجز", to: "/booking" },
    { name: `${HomePage.studyProgram[lang]}`, to: "/subscription" },
    { name: `${HomePage.aboutUs[lang]}`, to: "/about" },
    // { name: "meeting", to: "/call" },
  ];

  const teacherPages = [
    { name: `${HomePage.home[lang]}`, to: "" },
    { name: "جدول الحصص", to: "/teacher-table" },
    { name: "غرفة اجتماع", to: "/call" },
    { name: " تحميل صورة", to: "/upload" },
    { name: "تحميل  فيديو", to: "upload-video" },
    { name: "الحجوزات", to: "/bookings" },
    { name: "الرسائل", to: "/messages" },
  ];

  const adminPages = [
    ...publicPages,
    // { name: " لوحة التحكم ", to: "/admin-dashboard" },
  ];

  return (
    <header id="header" className="public_bg" style={{ backgroundColor: "white", color: "#1F3662" }} dir={`${dir}`}>
      {windowSize.width > 1000 ? (
        <>
          <List menuColor={menuColor} publicPages={publicPages} studentPages={studentPages} teacherPages={teacherPages} adminPages={adminPages} />

          <ul className="action">
            <li className="list-btn">
              {user.auth ? (
                <div className="d-flex justify-content-between align-items-center " style={{ width: "350px" }}>
                  <Link className="btn" to={`/user/${user._id}`} style={{ background: "#283476", color: "white", textDecoration: "none", borderRadius: "10px", padding: "10px" }}>
                    {HomePage.dashboard[lang]}
                  </Link>
                  <a href="/" onClick={onClick}>
                    <Button
                      name={`${HomePage.logOut[lang]}`}
                      width="100px"
                      id="logout-btn"
                      className={"login-register-btn"}
                    />
                  </a>
                  <div className="mx-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        dispatch(setLang(e.target.value));
                      }}
                      value={lang}
                    >
                      <option value="EN">EN</option>
                      <option value="AR">AR</option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <div>
                    <NavButton
                      to="/login"
                      name={`${HomePage.registerNow[lang]}`}
                      width="170px"
                      perfix="header-call-us login-register-btn"
                    />
                  </div>
                  <div className="mx-3" style={{ width: "150px" }}>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        dispatch(setLang(e.target.value));
                      }}
                      value={lang}
                    >
                      <option value="EN">EN</option>
                      <option value="AR">AR</option>
                    </select>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </>
      ) : (
        <HeaderMobile publicPages={publicPages} studentPages={studentPages} teacherPages={teacherPages} adminPages={adminPages} />
      )}
      {/* {user.auth && (
        <div >
          <NavLink to={`/user/${user._id}`} style={{ background: "#283476", color: "white" }}>
            لوحة التحكم
          </NavLink>
        </div>
      )} */}

    </header>
  );
}

//-----List Component-------
const List = ({ menuColor, publicPages, studentPages, teacherPages, adminPages }) => {
  const user = useSelector(mapStateToProps);

  const pages = () => {
    if (user.role === "student") {
      return studentPages;
    } else if (user.role === "teacher") return teacherPages;
    else if (user.role === "Admin") return adminPages;
    else {
      return publicPages;
    }
  };

  return (
    <nav>
      <ul className="list">
        <li className="list-btn" style={{ width: "60%" }}>
          <Link to="/">
            <img src={Logo} alt={platformName} className="header-logo" />
          </Link>
        </li>
        {pages().map((p, i) => (
          <li className="" key={i}>
            <NavLink id={"header" + i} style={{ color: menuColor }} className="list-btn" to={p.to}>
              {p.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

//-----HeaderMobile Component-------
function HeaderMobile({ publicPages, studentPages, teacherPages, adminPages }) {
  const user = useSelector(mapStateToProps);
  const [openSide, setOpenSide] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    dispatch(Log_Out());
    navigate("/");
  };

  const pages = () => {
    if (user.role === "student") {
      return studentPages;
    } else if (user.role === "teacher") return teacherPages;
    else if (user.role === "Admin") return adminPages;
    else {
      return publicPages;
    }
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });



  return (
    <>
      <Link to="/">
        <img src={Logo} alt="Osbash Learning Platform" width="70px" className="main-logo " />
      </Link>
      <AiOutlineMenu color="#1F3662" style={{ marginTop: "10px" }} size="30px" onClick={() => setOpenSide(true)} />
      {
        user?.auth === false || user?.auth === undefined ? (
          <SideNave open={openSide} onClose={() => setOpenSide(false)} logo={Logo} user={user} main={[...publicPages, { name: `${HomePage.registerNow[lang]}`, to: "/login" },


          ]} />

        ) : (
          <SideNave open={openSide} onClose={() => setOpenSide(false)} logo={Logo} user={user} main={[...publicPages, { name: `${HomePage.dashboard[lang]}`, to: `/user/${user._id}` },

          { name: `${HomePage.logOut[lang]}`, to: "/login" }
          ]}
          />

        )
      }
      <br />
    </>
  );
}

const mapStateToProps = ({ loggedUser, language }) => {
  return { ...loggedUser.user, auth: loggedUser.auth };
};

export default Header;