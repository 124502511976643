import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../../translations/trans';
import { useDispatch, useSelector } from 'react-redux';
import { Delete_Single_Mock, Get_Mocks } from '../../../actions/mockAction';
import { ErrorMsg } from '../../../utils/Toast';
import { Get_Materials } from '../../../actions/curriculums/materials';

const DeleteMock = ({ mock, materialId }) => {
    const [show, setShow] = useState(false);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const [loading, setLoading] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    const handleDelete = async () => {
        try {
            setLoading(true)
            const res = await dispatch(Delete_Single_Mock(mock, materialId, loggedUser?.user?._id))
            handleClose();
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
            // dispatch(Get_Materials())
        }
    }

    return (
        <div>
            <Button variant="danger" onClick={handleShow}>
                {HomePage.delete[lang]}
            </Button>

            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Header>
                    <Modal.Title>{HomePage.deleteMock[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{HomePage.infoDelMock[lang]}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} disabled={loading}>
                        {
                            !loading ? HomePage.delete[lang] : HomePage.loading[lang]
                        }
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {HomePage.cancel[lang]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DeleteMock;
