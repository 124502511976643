import React,{useRef} from 'react';
import axios from 'axios';
import {urlBase} from '../../utils/API.js';

function SendMessage(numbers) {
  const myNum = '966533497892';
  const pass ='Aa123123.'
  const message = useRef()
  const sentTo='201551661850'
  
  const apiRquest = (mess)=>{
    // 'https://hiwhats.com/API/send?mobile=966533497892&password=Aa123123.&instanceid=19864&message=test&numbers=201551661850&json=1&type=1'
     const api = `https://hiwhats.com/API/send?mobile=${myNum}&password=${pass}&instanceid=19864&message=${mess}&numbers=${sentTo}&json=1&type=1`
    return axios({
        method: "get",
        // withCredentials: true,
        url: api,
      });
   }

    const SendMessage = (e)=>{
     e.preventDefault();
     const encodedMessage = encodeURIComponent(message.current.value)
     apiRquest(encodedMessage);

    }
    
  return (
    <div>
      <h1>Hi whats .....</h1>
  <form onSubmit={SendMessage}>
    <label htmlFor="whats-message">message</label>
<br/> 
     <textarea ref={message} id='whats-message' rows='5' cols='70'/>
     <input type='submit' value='send'/>

  </form>

        </div>
  )
}

export default SendMessage