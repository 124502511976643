import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ErrorMsg, success } from '../../../utils/Toast';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { useSelector } from 'react-redux';
import { addLessonForContent } from '../../../utils/lessons';

const ModalAddLessson = ({
    show,
    handleClose,
    lessonData
}) => {

    const loggedUser = useSelector((state) => state.loggedUser);

    const uploadingFileLocally = async (image) => {
        const formData = new FormData();
        formData.append("file", image);
        const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
        return response.data.data;
    };


    const handleUpdateContent = async () => {
        setLoading(true)
        try {
            const file = image ? await uploadingFileLocally(image) : null
            if(file){
                const res = await addLessonForContent(file,lessonData?._id,loggedUser?.user?._id);
                if(res?.data?.case){
                    handleClose()
                    success("Lesson Added Successfully")
                }
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    }
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false)
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <input type="file" className="form-control" name="file-lesson" id="file-lesson"
                        onChange={(event) => {
                            setImage(event.target.files[0]);
                            const url = window.URL.createObjectURL(event.target.files[0]);

                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" disabled={loading} onClick={handleUpdateContent}>
                        {loading ? "loading..." : "Update"}
                    </button>
                    <button className="btn btn-secondary"  onClick={handleClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAddLessson
