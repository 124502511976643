import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
// import { getPackageById } from '../../../utils/packages';
import { useSelector } from 'react-redux';
import { getMocksPerClass } from '../../../utils/class';
import { urlBase } from '../../../utils/API';
import GetReportMock from './GetReportmock';

const ShowMockClass = ({ classData }) => {
    const [show, setShow] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [mocks, setMock] = useState([]);
    const [mockId, setMockId] = useState(null)


    const handleGetMocks = async () => {
        try {
            const res = await getMocksPerClass(classData?._id)
            if (res?.data?.case) {
                setMock(res?.data?.mocks)
            }
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        handleGetMocks();
    }, [classData])

    const handleReportMock = (mockId) => {
        setMockId(mockId)
        setShowReportModal(true);
        handleClose();
    }


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });



    return (
        <div>
            <button onClick={handleShow} className="btn" style={{ background: "#4169E2" }}>Show Mocks</button>
            <Modal show={show} onHide={handleClose} centered size="lg" dir={dir}>
                <Modal.Body>
                    <Table>
                        <thead>
                            <th>Name</th>
                            <th>File</th>
                            <th>Description</th>
                            <th>Report Mock</th>
                        </thead>
                        <tbody>
                            {
                                mocks?.map((mock) => {
                                    return (
                                        <tr key={mock?._id}>
                                            <td>{mock?.name}</td>
                                            <td>
                                                <a href={`${urlBase}/${mock?.file}`} target="_blank">Show File</a>
                                            </td>
                                            <td>{mock?.description}</td>
                                            <td>
                                                <button className="btn" style={{ background: "#4169E2" }}
                                                    onClick={() => handleReportMock(mock?._id)}
                                                >Show Report</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            <div>
                <GetReportMock
                    show={showReportModal}
                    handleClose={setShowReportModal}
                    mockId={mockId}
                    classId={classData?._id}
                />
            </div>
        </div>
    )
}

export default ShowMockClass
