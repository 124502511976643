import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { addHomeWork } from '../../utils/homeworkapi';
import { ErrorMsg, success } from '../../utils/Toast';
import axios from 'axios';
import { urlBase } from '../../utils/API';
import { HomePage } from '../../translations/trans';

const UploadHomeWork = ({ session, show, handleClose }) => {
    // const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const params = useParams();
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [file, setFile] = useState(null);
    // const [loading, setLoading] = useState(false)

    const loggedUser = useSelector((state) => state.loggedUser)


    const uploadingFileLocally = (image) => {
        const formData = new FormData();
        formData.append("file", image)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
            data: formData,
        })
    }

    const handleUploadHomeWork = async () => {
        setLoading(true)
        try {
            setLoading(true)
            const uploadRes = await uploadingFileLocally(file);
            const res = await addHomeWork({
                classId: params?.classId,
                sessionId: session?._id,
                studentId: loggedUser?.user?._id,
                file: uploadRes?.data?.data?.name,
            })
            setLoading(false)
            if (res?.data?.case) {
                success(res?.data?.message);
                handleClose();
            }
            else {
                ErrorMsg(res?.data?.message)
            }
        } catch (e) {
            setLoading(false)
            ErrorMsg(e?.message)
            console.log(e)
        } finally {
            setLoading(false)
        }
    }


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <div>
                {/* <button className='mx-3' onClick={handleShow}
                    style={{ color: "white", background: "#283476", padding: "10px", borderRadius: "10px", border: "none" }}>{title}</button> */}


                <Modal show={show} onHide={handleClose} centered className='py-5' dir={dir}>
                    <Modal.Body>
                        <input type="file" name="file" id="file" className='form-control'
                            onChange={(event) => { setFile(event.target.files[0]) }}
                        />
                    </Modal.Body>
                    <div className='m-auto text-center w-50' style={{ padding: "80px" }} >
                        <button onClick={handleUploadHomeWork} className='w-100'  disabled={loading} style={{ textAlign: "center", borderRadius: "10px", border: "none", outline: "none", background: "#283476", color: "white", padding: "10px" }}
                        >
                            {loading ? "Loading..." : HomePage.upload[lang]}
                        </button>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default UploadHomeWork;