import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { getReportMock } from '../../../utils/class';

const GetReportMock = ({
    show,
    handleClose,
    mockId,
    classId
}) => {

    const [report, setReport] = useState({});

    const loggedUser = useSelector((state) => state.loggedUser)

    const handleReportMock = async () => {
        try {
            const res = await getReportMock(mockId, classId, loggedUser?.user?._id)
            if (res?.data?.case) {
                setReport(res?.data?.mockReport?.report)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (mockId && classId) {
            handleReportMock();
        }
    }, [classId, mockId])

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size="lg" dir={dir}>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Marks</th>
                                <th>State</th>
                                <th>Mock Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Loop through the report object */}
                            {Object.entries(report).map(([key, mockArray]) => (
                                mockArray.map((mock, index) => (
                                    <tr key={`${key}-${index}`}>
                                        <td>{mock.userName}</td>
                                        <td>{mock.marks !== null ? mock.marks : 'N/A'}</td>
                                        <td>{mock.state}</td>
                                        <td>{mock.mockName}</td>
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    {/* You can add footer actions here if needed */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default GetReportMock
