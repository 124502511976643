import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { TbReportAnalytics } from "react-icons/tb";
import { getReportForStd } from '../../utils/class';
import { useSelector } from 'react-redux';
import { urlBase } from '../../utils/API';
import { ErrorMsg } from '../../utils/Toast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReportForAdmin = ({
    std, classData
}) => {
    const [show, setShow] = useState(false);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [loading, setLoading] = useState(false)

    const loggedUser = useSelector((state) => state.loggedUser)

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const formatDateToISO = (date) => {
        if (!date) return null;
        const isoDate = new Date(date).toISOString(); // Format as YYYY-MM-DDTHH:MM:SSZ
        return isoDate.replace('Z', '+03:00'); // Replace Z with +03:00 for the timezone
    };

    const [report, setReport] = useState(null)

    const handleReport = async () => {
        setLoading(true)
        try {
            const formattedFrom = formatDateToISO(dateFrom);
            const formattedTo = formatDateToISO(dateTo);

            const res = await getReportForStd({
                from: formattedFrom,
                to: formattedTo,
            }, classData?._id, std?._id, loggedUser?.user?._id);
            if (res?.data?.case) {
                setReport(res?.data?.report)
            }
        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        } finally {
            // setDateFrom("")
            // setDateTo("")
            setLoading(false)
        }
    };

    const handleSubmit = () => {
        handleReport();
        // handleClose();
    };



    return (
        <div>
            <TbReportAnalytics onClick={handleShow} size="25px" />
            <Modal show={show} onHide={handleClose} centered size="lg" dir={"ltr"}>
                <Modal.Header>
                    Reports
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>From:</label>
                            <br/>
                            {/* <input
                                type='date'
                                className='form-control'
                                id="dateFrom"
                                name="dateFrom"
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.target.value)}
                            /> */}
                            <DatePicker
                                selected={dateFrom ? new Date(dateFrom) : null}
                                onChange={(date) => setDateFrom(date)}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                placeholderText="Select date"
                            />
                        </div>
                        <div>
                            <label>To:</label>
                            <br/>
                            {/* <input
                                type='date'
                                className='form-control'
                                id="dateTo"
                                name="dateTo"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                            /> */}
                            <DatePicker
                                selected={dateTo ? new Date(dateTo) : null}
                                onChange={(date) => setDateTo(date)}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                placeholderText="Select date"
                            />

                        </div>
                    </div>
                    <div className='mt-5'>

                        {
                            report?.sessions?.length >= 0 ? (
                                <div>
                                    <h5>Sessions Report:</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Session Name</th>
                                                <th>Session Date</th>
                                                <th>Session H.W</th>
                                                <th>Session Attended</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                report?.sessions?.length > 0 ? (
                                                    report?.sessions?.map((session) => {
                                                        return (
                                                            <tr key={session?.session}>
                                                                <td>{session?.name}</td>
                                                                <td>{session?.date}</td>
                                                                <td>{session?.homework}</td>
                                                                <td>{session?.attended}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : null
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            ) : null
                        }
                    </div>


                    <div className='mt-5'>

                        {
                            report?.mockAnswers?.length >= 0 ? (
                                <div>
                                    <h5>Mocks Report:</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Mock Name</th>
                                                <th>Mock Marks</th>
                                                <th>Mock State</th>
                                                <th>Mock File</th>
                                                <th>Answer File</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                report?.mockAnswers?.length > 0 ? (
                                                    report?.mockAnswers?.map((mock) => {
                                                        return (
                                                            <tr key={mock?.mock?.name}>
                                                                <td>{mock?.mock?.name}</td>
                                                                <td>{mock?.marks}</td>
                                                                <td>{mock?.state}</td>
                                                                <td>
                                                                    <a href={`${urlBase}/${mock?.mock?.file}`} target="_blank" rel="noreferrer">PDF File</a>
                                                                </td>
                                                                <td>
                                                                    <a href={`${urlBase}/${mock?.file}`} target="_blank" rel="noreferrer">PDF Answer</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : null
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            ) : null
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant='primary' onClick={handleSubmit} disabled={loading}>
                        {loading ? "loading..." : "Submit"}
                        {/* Submit */}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ReportForAdmin;


