import { NEW_CLASS, GET_CLASSES, ADD_STUDENTS, CHANGE_ACTIVE_STATE, CREATE_ROOM_ID, UPDATE_CLASSES } from "../actions/class";

export const classes = (state = [], action) => {
    switch (action.type) {
        case NEW_CLASS:
            return state = [...state, action.Class]
        case UPDATE_CLASSES:
          
            return state = state.map(s => {
                if (s._id === action.classes._id) {
                    return action.classes
                }
                return s
            })
        case GET_CLASSES:
            return state = action.classes
        case ADD_STUDENTS:
            return state.map(s => {
                if (s._id === action.Class._id) {
                    return action.Class
                }
                return s
            })
        case CHANGE_ACTIVE_STATE:
            return state.map(s => {
                if (s._id === action.Class._id) {
                    return action.Class
                }
                return s
            })
        case CREATE_ROOM_ID:
            return state.map(s => {
                if (s._id === action.Class._id) {
                    return action.Class
                }
                return s
            })
        default: return state
    }
}